import { X_INCREMENT_WIDTH, Y_INCREMENT_HEIGHT } from '../config'

import InternalCellPosition from '../models/InternalCellPosition'
import Point from '../models/Point'

/**
 * Get the pixel coordinates relative to the top left grid point of the
 * containing cell.
 * @param mousePixel The mouse position to get the relative position of.
 * @returns The internal pixel coordinates.
 */
function getInternalPixelCoordinates (mousePixel: Point): Point {
  return new Point(
    mousePixel.x % X_INCREMENT_WIDTH,
    mousePixel.y % Y_INCREMENT_HEIGHT
  )
}

export function getNearestInternalCellPositionThatIsNotCentre (
  mousePixel: Point
): Exclude<InternalCellPosition, InternalCellPosition.Centre> {
  const internalPixel = getInternalPixelCoordinates(mousePixel)

  const inLeftOrBottom = internalPixel.y > internalPixel.x
  const inRightOrBottom = internalPixel.y >
    -(Y_INCREMENT_HEIGHT / X_INCREMENT_WIDTH) * internalPixel.x +
    Y_INCREMENT_HEIGHT

  if (inLeftOrBottom && inRightOrBottom) {
    return InternalCellPosition.Bottom
  } else if (inLeftOrBottom && !inRightOrBottom) {
    return InternalCellPosition.Left
  } else if (!inLeftOrBottom && inRightOrBottom) {
    return InternalCellPosition.Right
  } else { // !inLeftOrBottom && !inRightOrBottom
    return InternalCellPosition.Top
  }
}

export function getNearestInternalCellPosition (
  mousePixel: Point
): InternalCellPosition {
  const internalPixel = getInternalPixelCoordinates(mousePixel)

  // If it is in the centre return that.
  if (
    internalPixel.x > X_INCREMENT_WIDTH * 1 / 4 &&
    internalPixel.x < X_INCREMENT_WIDTH * 3 / 4 &&
    internalPixel.y > Y_INCREMENT_HEIGHT * 1 / 4 &&
    internalPixel.y < Y_INCREMENT_HEIGHT * 3 / 4
  ) {
    return InternalCellPosition.Centre
  }

  // Otherwise work out which quadrant it is in.
  return getNearestInternalCellPositionThatIsNotCentre(mousePixel)
}
