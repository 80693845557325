import Point from '../models/Point'
import ToolMode from '../models/ToolMode'
import Wall from '../models/Wall'
import Floor from '../models/Floor'
import OptionalItemData from '../models/OptionalItemData'
import Item from '../models/Item'
import Frame from '../models/Frame'
import Bench from '../models/Bench'

/**
 * Based on an item and a tool mode, return whether or not an item can be
 * hovered (and hence selected).
 * @param item The item to check.
 * @param toolMode The tool mode to check.
 * @returns True if the item _could_ be hovered, false otherwise.
 */
function itemIsHoverable (item: Item, toolMode: ToolMode): boolean {
  // This functionality may be able to be simplified / abstracted further, but
  // I'm not sure we actually want to enforce the generalisation that appear to
  // hold for now, in the long term.
  return (
    (
      (item instanceof Wall) &&
      [ToolMode.Wall, ToolMode.LinkedWall, ToolMode.Delete].includes(toolMode)
    ) ||
    (
      (item instanceof Floor) &&
      [ToolMode.Floor, ToolMode.Delete].includes(toolMode)
    ) ||
    (
      (item instanceof Bench) &&
      [ToolMode.Bench, ToolMode.Delete].includes(toolMode)
    ) ||
    (
      (item instanceof Frame) &&
      [ToolMode.Frame, ToolMode.Delete].includes(toolMode)
    )
  )
}

/**
 * Returns the item that should currently be considered "hovered". Note that
 * this function effectively controls what can be selected in each tool mode.
 * @param context The context of the canvas to check this on.
 * @param mousePixel The pixel coordinate point of the mouse.
 * @param items The keyed object containing the items.
 * @param toolMode The current mode of the tool.
 * @returns Details of the currently selected item.
 */
export default function getHoveredItemData (
  context: CanvasRenderingContext2D,
  mousePixel: Point,
  items: Record<string, Item>,
  toolMode: ToolMode
): OptionalItemData {
  // We want the thing that is hovered to be the thing that looks like it is at
  // the top. Objects are drawn in an order determined by sorting the items by
  // their order property, so, we need to do the reverse of that.

  // I have used the terms "key" and "position" here to distinguish between the
  // two kinds of index. Key is the string index used by the items map, and is
  // what we must return, position is just it's numerical position in items.
  // They might not be the same, for instance if items have been deleted.

  const order = Object.entries(items)
    .map(([key, item], position): [string, number, Item] => [
      key, position, item
    ])
    .sort(([key1, position1, item1], [key2, position2, item2]) => {
      // Prioritises sorting by order, breaks ties with original order (all
      // reversed).
      if (item1.order !== item2.order) return item2.order - item1.order
      return position2 - position1
    })

  for (const [key,, item] of order) {
    if (
      itemIsHoverable(item, toolMode) &&
      item.containsPixelPoint(context, mousePixel)
    ) {
      return { index: key, item }
    }
  }

  return undefined
}
