/**
 * The modes of display that an item on the canvas can have.
 */
enum DisplayMode {
  New = 'New',
  Normal = 'Normal',
  Hovered = 'Hovered',
  Selected = 'Selected',
  Preview = 'Preview'
}

export default DisplayMode
