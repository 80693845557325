import Item from './Item'

type OptionalItemData = {
  index: string
  item: Item
} | undefined

export default OptionalItemData

/**
 * Check if two OptionalItemData objects represent the same information (despite
 * possibly being, like, different objects). Also, yes, I said Datas. What of
 * it.
 * @param optionalItemDataOne The first object to check.
 * @param optionalItemDataTwo The second object to check.
 * @returns True if they match, false otherwise.
 */
export function optionalItemDatasMatch (
  optionalItemDataOne: OptionalItemData,
  optionalItemDataTwo: OptionalItemData
): boolean {
  // If one of them are undefined, only return true if they are both undefined.
  if (optionalItemDataOne === undefined || optionalItemDataTwo === undefined) {
    return optionalItemDataOne === optionalItemDataTwo
  }

  return optionalItemDataOne.index === optionalItemDataTwo.index &&
    optionalItemDataOne.item === optionalItemDataTwo.item
}
