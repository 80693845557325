/**
 * The valid tool modes that the floor plan designer can be in.
 */
enum ToolMode {
  Pan = 'Pan',
  Wall = 'Wall',
  LinkedWall = 'LinkedWall',
  Floor = 'Floor',
  Camera = 'Camera',
  Bench = 'Bench',
  Frame = 'Frame',
  Delete = 'Delete'
}

export default ToolMode
