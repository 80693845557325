import React from 'react'

import Crumbs from '../Crumbs'

import './styles.css'

export default function HelpImportingIntoUnityPage () {
  return <div className='HelpImportingIntoUnityPage'>
    <div className='contents'>
      <Crumbs links={[['Help', '/help']]} current='Importing into Unity'/>
      <h1>Importing into Unity</h1>
      <p className='centred'>
        Once you've built your museum, you'll be left with a <code>.unitypackage
        </code> which needs to be added into a Unity project. Here, we will run
        through how to install Unity and how to import the Unity template with
        any extra tasks needed to fulfill the requirements of the museum.
      </p>
      <div className='section'>
        <h2 id='intall-header'>Installing Unity</h2>
        <p>
          It is recommended that even if you have Unity installed, the following
          guide should be followed to ensure that all requirements are met and
          that the template can be properly imported into your Unity project.
        </p>
        <ol>
          <li>
            Download the latest <a
            href='https://unity3d.com/get-unity/download/archive'>Unity Hub
            installer</a>. Open the installer and follow the installation
            steps, then open the Unity Hub application.
          </li>
          <li>
            We now need to create a suitable instance for the project.
          </li>
          <ul>
              <li>
                Navigate to the <code>Installs</code> tab. Press <b>Add</b> to
                create a new installation for Unity.
              </li>
              <li>
                Select a release that has <b>(LTS)</b>, either the recommended
                release or the most recent official release. Press <b>Next</b>.
              </li>
              <img
                src='/unity-version-option.png'
                alt='Selecting a Unity version for an installation'
              />
              <li>
                Select the <b>WebGL Build Support</b> module as shown below. Add
                the respective module for your operating system e.g. <i>Mac
                Build Support (IL2CPP)</i>. Press <b>Done</b>.
              </li>
              <img
                src='/unity-module-option.png'
                alt='Selecting Unity modules for an installation'
              />
            </ul>
        </ol>
        <p className='centred'>
          Once the installation is complete, it will appear in your <code>
          Installs</code> tab. We are now able to create a project in Unity!
        </p>
        <img
          src='/unity-installs.png'
          alt='Unity Installs tab with the new installation'
        />
      </div>
      <div className='section'>
        <h2>Creating a Unity Project</h2>
        <p>
          With a <code>.unitypackage</code> generated from the tool, we need to
          create a Unity project so that it can be imported and used. There are
          multiple ways this can be done:
        </p>
        <ol>
          <li>
            Navigate to the <code>Projects</code> tab on Unity Hub. Press <b>
            New</b>.
          </li>
          <img
            src='/unity-hub-projects-empty.png'
            alt='Unity Projects tab for a new project'
          />
          <li>
            We need to ensure the following selections are made for the project:
          </li>
          <ul>
            <li>
              Select a <b>3D Template</b>.
            </li>
            <li>
              Create a project name for your museum.
            </li>
            <li>
              Select where on your computer you would like to save the project.
            </li>
            <li>
              Press <b>Create</b>.
            </li>
          </ul>
          <img
            src='/unity-new-project-exmaple.png'
            alt='New Unity project options'
          />
        </ol>
        <p className='centred'>
          Now you'll have an empty Unity scene to work with.
        </p>
        <img
            src='/unity-empty-scene.png'
            alt='Example empty Unity scene'
          />
      </div>
      <div className='section'>
        <h2 id='import-header'>Importing the Template into the Project</h2>
        <p>
          When importing the <code>.unitypackage</code> into Unity, the first
          step is to import this asset package into the scene. There are
          multiple ways this can be done:
        </p>
        <ol>
          <li>
            Importing the scene as a <i>custom package</i>:
          </li>
          <ul>
            <li>
              Navigate to <code>Assets {'>'} Import Package {'> '}
              Custom Package...</code> and select your <code>.unitypackage
              </code>.
            </li>
            <img
              src='/unity-import-tab.png'
              alt='Importing an asset package from the menu bar'
            />
          </ul>
          <li>
            Importing the scene by dragging into the <code>Assets</code> tab:
          </li>
          <ul>
            <li>
              Find your <code>.unitypackage</code> file in your folders.
            </li>
            <li>
              Drag the file into the <code>Assets</code> tab in the Unity editor.
            </li>
            <img
              src='/unity-assets-tab.png'
              alt='Placeholder for where the asset package is dragged'
            />
          </ul>
        </ol>
        <p>
          For both (1) and (2), from the <code>Import</code> popup box, select
          <b> Import</b> and ensure no boxes are unticked when doing so.
        </p>
        <img
          src='/unity-import-package.png'
          alt='Placeholder for handling the Import Package tab in Unity'
        />
      </div>
      <div className='section'>
        <h2 id='multiplayer-header'>Setting Up Multiplayer</h2>
        <p>
          Setting up multiplayer only takes a few simple steps:
        </p>
        <ol>
          <li>
            Create your <a
            href='https://www.photonengine.com/en-US/Photon'>Photon</a> apps.
          </li>
          <ul>
            <li>
              Make an account with the Photon website and go to your <a
              href='https://dashboard.photonengine.com/en-US/'>Dashboard</a>.
            </li>
            <li>
              Create a new app. Set the <code>Photon Type</code> to <b>Photon
              PUN</b>. Set the <code>Name</code> to any name you want.
            </li>
            <li>
              Create a second new app. Set the <code>Photon Type</code> to <b>
              Photon Voice</b>. Set the <code>Name</code> to any name you
              want.
            </li>
            <li>
              Keep track of the App IDs for both of the apps you just made.
              These are important for later.
            </li>
          </ul>
          <img
            src='/photon-app-id.png'
            alt='Finding the App IDs in the Photon Dashboard.'
          />
          <li>
            Install Photon in your Unity Project.
          </li>
          <ul>
            <li>
              Open the Asset Store website by navigating to <code>Window
              {' >'} Asset Store</code> then clicking the <code>Search
              Online</code> button.
            </li>
            <img
              src='/asset_store.png'
              alt='Accessing the Asset Store from the Menu Bar.'
            />
            <li>
              Search for <b>"Photon Voice 2"</b> using the search bar. Click on
              the Photon Voice 2 Asset and press the <code>Add to My
              Assets</code> button. When this finishes, click the <code>Open in
              Unity</code> button that appears.
            </li>
            <img
              src='/voice_asset.png'
              alt='Finding the Photon Voice 2 Unity Asset.'
            />
            <li>
              The step above should open the <b>Package Manager</b> window. You
              can do this manually by navigating to <code>Window {'>'} Package
              Manager</code>. Make sure that you are on the <b>My Assets</b> tab
              in the Package Manager.
            </li>
            <li>
              When the asset has opened in your Unity Project, click on
              the <code>Download</code> and <code>Import</code> button.
            </li>
            <img
              src='/import_voice.png'
              alt='Importing the Photon Voice 2 Unity Asset.'
            />
            <li>
              The <b>PUN Setup Wizard</b> should appear once you finish
              importing. Grab the first App ID from Step 1 (the ID for the
              <b> Photon PUN</b> app) and paste it in the provided space. Then
              click the <code>Setup Project</code> button.
            </li>
            <img
              src='/pun_wizard.png'
              alt='Setting up Photon PUN in Unity.'
            />
            <li>
              Afterwards, the Photon Settings should open in the <b>Unity
              Inspector</b>. Click on <code>Server/Cloud Settings</code>. Grab
              the second App ID from Step 1 (the ID for the <b>Photon Voice </b>
              app) and paste it in the <code>App Id Voice</code> space.
            </li>
            <img
              src='/pun_settings.png'
              alt='Setting up Photon Voice in Unity.'
            />
          </ul>
        </ol>
      </div>
      <div className='section'>
        <h2 id='playing-header'>Playing The Game</h2>
        <p>
          Make sure that you are always on the <code>Scene</code> tab when
          working on this Unity Project. To test how the game works you can
          press the play button at the top of the Unity Editor. By default
          the project is in Single Player Mode when you press play.
        </p>
        <p>
          To test Multiplayer and Voice Chat you first need to navigate
          to <code>Camera {'>'} Camera - 1</code> in the <b>Hierarchy</b> tab.
          Then in the <b>Unity Inspector</b> you should see options to turn
          Multiplayer and Voice Chat on. When you press play, the Unity Project
          will check if these options are on.
        </p>
        <img
          src='/play_mode.png'
          alt='Setting the Mode of Play in the Unity Project.'
        />
      </div>
    </div>
  </div>
}
