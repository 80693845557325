import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

import Button from '../Button'
import HelpCards from '../HelpCards'

import './styles.css'

export default function HomePage () {
  const scrollToElementReference = useRef<HTMLDivElement>(null)

  return <div className="HomePage">
    <div className="hero dark">
      <div className="content">
        <h1>MEDSUnited</h1>
        <p>Design your museum, the easy way.</p>
        <div className="buttons">
          <Link to="/tool"><Button light>Get Started</Button></Link>
          <Link to="/help"><Button>Learn How</Button></Link>
        </div>
        <p>
          <div className="arrow" onClick={() => {
            // TODO: Make this not scroll the title behind the header.
            scrollToElementReference?.current
              ?.scrollIntoView({ behavior: 'smooth' })
          }}></div>
        </p>
      </div>
    </div>
    <div ref={scrollToElementReference} className="scroll-padding dark"/>
    <div className="content">
      <h1>What is this?</h1>
      <p>
        This website contains a Unity floor plan designer web app, and
        instructions to help you get the most out of it. The tool will let you
        place walls, floors, and other assets in a 2D top-down UI, to help
        quickly sketch out your museum's layout.
      </p>
      <h1>Documentation</h1>
      <HelpCards />
    </div>
  </div>
}
