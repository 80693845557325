import {
  NUMBER_OF_CELLS_HIGH,
  X_INCREMENT_WIDTH,
  Y_INCREMENT_HEIGHT
} from '../config'

/*

TODO: Considering making this store what kind of point it was referring to,
and only allowing appropriate method calls. It could look something like the
enum below? Or perhaps they could be different types? I'm not sure.

/**
 * Describes a "type" that a point can have. FrontendGrid means that the point
 * refers to an intersection on the grid of the floor plan too. BackendGrid is
 * the same as FrontendGrid, but it has been prepared for the backend as it is
 * the only type in which the origin is in the bottom left, rather than the top
 * left. DisplayedPixel means that it refers to a pixel coordinate in the real
 * canvas rendered on the DOM. FloorPlanPixel means that it refers to a pixel
 * coordinate within the floor plan canvas, which will be offset and scaled
 * compared to the DisplayedPixel equivalent.
 *~/
 export enum PointType {
  FrontendGrid = 'FrontendGrid',
  BackendGrid = 'BackendGrid',
  DisplayedPixel = 'DisplayedPixel',
  FloorPlanPixel = 'FloorPlanPixel'
}
*/

/**
 * A class for storing and manipulating 2D integer vectors. This can be used to
 * represent either grid points, or pixel points, but this class does not track
 * which of those it is representing, it will just compare / use the x and y
 * values raw. Tracking the space the point is meaningfully representative in is
 * the responsibility of the code using it.
 */
export default class Point {
  x: number
  y: number

  /**
   * Create a new Point at / with the given coordinates / values. They will be
   * floored to integers before being stored.
   * @param x The x coordinate.
   * @param y The y coordinate.
   */
  constructor (x: number, y: number) {
    this.x = Math.floor(x)
    this.y = Math.floor(y)
  }

  /**
   * Check if this point represents the same position as another point.
   * @param otherPoint The other point to check.
   * @returns True if they match, false if they don't.
   */
  equals (otherPoint: Point) {
    return this.x === otherPoint.x && this.y === otherPoint.y
  }

  /**
   * Get a new point representing the sum of this point and another point.
   * @param otherPoint The point to add to this point.
   * @returns A new point object storing the outcome of the addition.
   */
  plus (otherPoint: Point) {
    return new Point(this.x + otherPoint.x, this.y + otherPoint.y)
  }

  /**
   * Get a new point representing the difference between this point and another
   * point.
   * @param otherPoint The point to subtract from this point.
   * @returns A new point object storing the outcome of the subtraction.
   */
  minus (otherPoint: Point) {
    return new Point(this.x - otherPoint.x, this.y - otherPoint.y)
  }

  /**
   * Get a new point representing the result of scaling this point by a given
   * amount.
   * @param scalar The amount to scale this point by.
   * @returns A new point object storing the outcome of the scaling.
   */
  times (scalar: number) {
    return new Point(this.x * scalar, this.y * scalar)
  }

  /**
   * Convert this point between the frontend coordinate system (origin top left,
   * all positive coordinates) and the backend coordinate system (origin bottom
   * left, all positive coordinates). As the process is just flipping the y
   * axis, it is the same both ways.
   * @returns A new point object now in the other coordinate system.
   */
  toggleBackendCoordinates () {
    return new Point(this.x, NUMBER_OF_CELLS_HIGH - this.y)
  }

  /**
   * Assuming that this is a pixel coordinate relative to the floor plan tool
   * canvas, this gets the top left coordinate of the cell currently containing
   * this point. This can serve as an identifier of the "current cell", in a way
   * that the nearest grid point does not.
   * @returns A new point object storing the grid coordinates of the top left
   * corner of the current cell.
   */
  topLeftGridPoint () {
    return new Point(this.x / X_INCREMENT_WIDTH, this.y / Y_INCREMENT_HEIGHT)
  }

  /**
   * Assuming that this is a pixel coordinate in the floor plan canvas, this
   * gets the grid coordinate of the nearest grid point (grid line intersection)
   * on the floor plan canvas to this point.
   * @returns A new point object storing the grid coordinates of the nearest
   * grid point.
   */
  nearestGridPoint () {
    const topLeftGridPoint = this.topLeftGridPoint()

    const acrossHalf = this.x % X_INCREMENT_WIDTH > X_INCREMENT_WIDTH / 2
    const downHalf = this.y % Y_INCREMENT_HEIGHT > Y_INCREMENT_HEIGHT / 2

    return new Point(
      topLeftGridPoint.x + (acrossHalf ? 1 : 0),
      topLeftGridPoint.y + (downHalf ? 1 : 0)
    )
  }

  /**
   * Assuming that this is a grid point, get the pixel coordinates of the grid
   * point in the floor plan canvas.
   * @returns A new point object storing the floor plan canvas pixel
   * coordinates.
   */
  pixelPoint () {
    return new Point(this.x * X_INCREMENT_WIDTH, this.y * Y_INCREMENT_HEIGHT)
  }
}
