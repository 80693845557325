import {
  cameraBottom,
  cameraLeft,
  cameraRight,
  cameraTop
} from '../utilities/images'

import ImageItem from './ImageItem'
import InternalCellPosition, { Direction } from './InternalCellPosition'
import Point from './Point'

export default class Camera extends ImageItem {
  static defaultOrder = 5
  order = Camera.defaultOrder

  path: Path2D = new Path2D()

  imageMapping = {
    [InternalCellPosition.Top]: cameraTop,
    [InternalCellPosition.Bottom]: cameraBottom,
    [InternalCellPosition.Left]: cameraLeft,
    [InternalCellPosition.Right]: cameraRight
  }

  getObjectForSerialisation () {
    const backendTopLeft = this.topLeftGridPoint.toggleBackendCoordinates()
    const backendBottomLeft = new Point(
      backendTopLeft.x,
      backendTopLeft.y - 1
    )

    type Rotation = 'up' | 'down' | 'left' | 'right'
    const rotationMapping: Record<Direction, Rotation> = {
      [InternalCellPosition.Top]: 'up',
      [InternalCellPosition.Bottom]: 'down',
      [InternalCellPosition.Left]: 'left',
      [InternalCellPosition.Right]: 'right'
    }

    return {
      x: backendBottomLeft.x,
      y: backendBottomLeft.y,
      rot: rotationMapping[this.direction]
    }
  }
}
