import React from 'react'
import { Link } from 'react-router-dom'

import Edit from '@material-ui/icons/Edit'
import Timeline from '@material-ui/icons/Timeline'
import CropSquare from '@material-ui/icons/CropSquare'
import Delete from '@material-ui/icons/Delete'
import GetApp from '@material-ui/icons/GetApp'
import Videocam from '@material-ui/icons/Videocam'
import PanTool from '@material-ui/icons/PanTool'
import Weekend from '@material-ui/icons/Weekend'
import FilterFrames from '@material-ui/icons/FilterFrames'
import CenterFocusStrong from '@material-ui/icons/CenterFocusStrong'

import Crumbs from '../Crumbs'

import './styles.css'

export default function HelpUsingTheTool () {
  return <div className="HelpUsingTheTool">
    <div className='contents'>
      <Crumbs links={[['Help', '/help']]} current="Using the Tool"/>
      <h1>Using the Tool</h1>
      <p className='centred'>
        This tool is designed to support your journey in creating a museum in
        Unity. Remember, you can come back to this page at any time and your
        progress won't be lost. Let's begin by walking through some of the ways
        you can create an amazing and expressive museum!
      </p>
      <div className='section pan-zoom-tool'>
        <PanTool className='icon' />
        <div className='tool-information'>
          <h2>Pan & Zoom Tool</h2>
          <p>
            This tool allows you to move around the canvas so you can get a
            better perspective of different sections of your museum. Zooming in
            and out of the tool can be done through scrolling. Note that zooming
            can be done regardless of the tool you have selected.
          </p>
        </div>
      </div>
      <div className='section wall-tool'>
        <Edit className='icon' />
        <div className='tool-information'>
          <h2>Wall Drawing Tool</h2>
          <p>
            This tool is used by dragging across a wall between two points on
            the floor plan grid. A preview of this wall is shown as you are
            dragging. Once a wall is placed, you can click it again and drag
            the ends of the walls to a new position.
          </p>
        </div>
      </div>
      <div className='section connected-wall-tool'>
        <Timeline className='icon' />
        <div className='tool-information'>
          <h2>Connected Wall Drawing Tool</h2>
          <p>
            This tool is used the same way as the wall drawing tool but once the
            first wall is placed, you can then click another point to draw a
            connected wall. Walls can also be editted the same way as described
            for the wall drawing tool.
          </p>
        </div>
      </div>
      <div className='section floor-tool'>
        <CropSquare className='icon' />
        <div className='tool-information'>
          <h2>Floor Drawing Tool</h2>
          <p>
            Every <i>good</i> museum needs a floor! This tool will allow
            dragging a rectangular floor between two points on the floor plan
            grid. You can click on the floor again to edit its size and shape.
            You should make each floor section separate especially if you want
            to give them separate textures!
          </p>
        </div>
      </div>
      <div className='section camera-tool'>
        <Videocam className='icon' />
        <div className='tool-information'>
          <h2>Camera Position Tool</h2>
          <p>
            When you start in your museum, you want to make sure you're standing
            in the right spot. This tool places a camera which will be your
            starting point when you hit play, but make sure to face it in the
            right direction also! It will follow the direction of your cursor in
            each grid square.
          </p>
        </div>
      </div>
      <div className='section delete-tool'>
        <Delete className='icon' />
        <div className='tool-information'>
          <h2>Delete Tool</h2>
          <p>
            When you make a mistake in your museum, this tool can be used to
            remove any walls, floors or objects. Just click anything that isn't
            wanted, but this won't work on the camera (you need to be able to
            see in a museum)!
          </p>
        </div>
      </div>
      <div className='section bench-tool'>
        <Weekend className='icon' />
        <div className='tool-information'>
          <h2>Bench Tool</h2>
          <p>
            People visiting your museum need a place to sit after admiring all
            the great artworks. Click different places on the floor plan to add
            a bench prefab which sit great against walls or even back-to-back.
            For more info, check out the <Link to="/help/modifying-in-unity">
            modifying your museum</Link> page for more help.
          </p>
        </div>
      </div>
      <div className='section frame-tool'>
        <FilterFrames className='icon' />
        <div className='tool-information'>
          <h2>Frame Tool</h2>
          <p>
            Every museum needs somewhere to hang the artworks. Frames are great
            additions against walls in the floor plan tool and can be good
            pre-made prefabs for adding in an artwork, or function as a
            placeholder. For more info, check out the <Link
            to="/help/modifying-in-unity">modifying your museum</Link> page for
            more help.
          </p>
        </div>
      </div>
      <div className='section zoom-reset-tool'>
        <CenterFocusStrong className='icon' />
        <div className='tool-information'>
          <h2>Zoom Reset Button</h2>
          <p>
            Zooming in and around such a large museum can sometimes make us lose
            track of what we're making. This button will reset your zoom and
            position back to the camera on the floor plan no matter where its
            placed!
          </p>
        </div>
      </div>
      <div className='section download-button'>
        <GetApp className='icon' />
        <div className='tool-information'>
          <h2>Download Button</h2>
          <p>
            When you're all done with your museum, click on this button to get
            your Unity museum template. To add this template museum to Unity,
            check out <Link to="/help/importing-into-unity">our guide</Link>.
            This page will also help with installing Unity if you don't already
            have it!
          </p>
        </div>
      </div>
      <p className='centred'>
        Here's a glimpse of what you could make with this tool!
      </p>
      <img
        src="/tools-example-plan.png"
        alt="Example floor plan using the tool"
      />
      <img
        src="/tools-example-output.png"
        alt="Example output from floor plan tool in Unity"
      />
      <p className='centred'>
        Remember this is only the template and you can make any changes to
        create the museum best suited for what's inside. Take a look at the
        guide for <Link to="/help/modifying-in-unity">modifying your museum
        </Link> in Unity.
      </p>
    </div>
  </div>
}
