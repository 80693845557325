import Point from './Point'
import ItemType from './ItemType'
import ResizeNode from './ResizeNode'
import Item from './Item'
import DisplayMode from './DisplayMode'

import {
  COLOUR_FOR_ITEM_TYPE_FOR_DISPLAY_MODE,
  NORMAL_LINE_DASH,
  WALL_LINE_WIDTH
} from '../config'

/**
 * A class for handling walls.
 */
export default class Wall extends Item {
  static defaultOrder = 2
  order = Wall.defaultOrder

  points: [Point, Point]
  path: Path2D
  resizeNodes: [ResizeNode, ResizeNode]

  /**
   * Create a new wall object between two grid points. The wall must be either
   * vertical or horizontal.
   * @param startPoint The first grid point. This anchors the line, and will
   * definitely be an end point.
   * @param secondPoint The second grid point. This will not actually be the
   * other end point if it is not in a vertical or horizontal line with the
   * first, but it will determine in.
   */
  constructor (startPoint: Point, secondPoint: Point) {
    super()

    // Determine the end point.
    const horizontalDistance = Math.abs(startPoint.x - secondPoint.x)
    const verticalDistance = Math.abs(startPoint.y - secondPoint.y)
    const horizontal = horizontalDistance >= verticalDistance
    const endPoint = new Point(
      // The x coordinate only changes from the start point if it is horizontal.
      horizontal ? secondPoint.x : startPoint.x,
      // The y coordinate only changes from the end point if it is vertical.
      horizontal ? startPoint.y : secondPoint.y
    )

    // Store the line points.
    this.points = [startPoint, endPoint]

    const orderedPoints: [Point, Point] = [startPoint, endPoint]
    orderedPoints.sort((a, b) => horizontal ? a.x - b.x : a.y - b.y)

    const pixelOverlap = new Point(
      horizontal ? WALL_LINE_WIDTH / 2 : 0,
      horizontal ? 0 : WALL_LINE_WIDTH / 2
    )

    // Create the path.
    const pixelPoint1 = orderedPoints[0].pixelPoint().minus(pixelOverlap)
    const pixelPoint2 = orderedPoints[1].pixelPoint().plus(pixelOverlap)
    this.path = new Path2D()
    this.path.moveTo(pixelPoint1.x, pixelPoint1.y)
    this.path.lineTo(pixelPoint2.x, pixelPoint2.y)

    // Create the resize nodes.
    this.resizeNodes = [
      new ResizeNode(startPoint, endPoint),
      new ResizeNode(endPoint, startPoint)
    ]
  }

  draw (context: CanvasRenderingContext2D, displayMode: DisplayMode) {
    context.setLineDash(NORMAL_LINE_DASH)

    context.strokeStyle =
      COLOUR_FOR_ITEM_TYPE_FOR_DISPLAY_MODE[ItemType.Wall][displayMode]
    context.lineWidth = WALL_LINE_WIDTH

    context.stroke(this.path)
  }

  getObjectForSerialisation () {
    const startPointConverted = this.points[0].toggleBackendCoordinates()
    const endPointConverted = this.points[1].toggleBackendCoordinates()

    // I just wanted to spell it out explicitly, so we can see the structure.
    return [
      { x: startPointConverted.x, y: startPointConverted.y },
      { x: endPointConverted.x, y: endPointConverted.y }
    ]
  }

  containsPixelPoint (
    context: CanvasRenderingContext2D,
    pixelPoint: Point
  ) {
    // Just ensure that the stroke width is set correctly before checking if
    // this contains something.
    context.lineWidth = WALL_LINE_WIDTH
    return super.containsPixelPoint(context, pixelPoint)
  }
}
