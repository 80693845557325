import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
  links: [display: string, url: string][],
  current: string
}

export default function Crumbs ({ links, current }: Props) {
  return <p>
    {links.map(([display, url]) => <span key={url}>
      <Link to={url}>{display}</Link>
      {' > '}
    </span>)}
    <span>{current}</span>
  </p>
}
