import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'

import './styles.css'

export default function Header () {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  // We attach an click event listener to the document such that any clicks that
  // aren't on the menu close the menu. We  need to stop propagation on the menu
  // button itself, such that it isn't closed immediately after it is opened. We
  // don't need to stop propagation on the menu itself, because every click on
  // the menu _should_ be a click on a link, and when that happens we want the
  // menu to close anyway.
  useEffect(
    () => {
      const listener = () => {
        setMenuIsOpen(false)
      }

      document.addEventListener('click', listener)

      return () => document.removeEventListener('click', listener)
    },
    []
  )

  return <div className="Header">
    <div className="spacer"></div>
    <header className="dark">
      <div className="content">
        <nav className="header-navigation">
          <ul>
            <li>
              <Link className="item-container" to="/">
                <img
                  className="logo"
                  src="/logo-no-border.png"
                  alt="MedsUnited logo depicting a pixel-art museum"
                ></img>
                <strong>MEDSUnited</strong>
              </Link>
            </li>
            <li className="menu-item">
              <Link className="item-container" to="/tool">
                <span>Tool</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link className="item-container" to="/help">
              <span>Help</span>
              </Link>
            </li>
            <li className="menu-button-item">
              <div className="menu-container">
                <div
                  className="item-container"
                  onClick={event => {
                    event.stopPropagation()
                    setMenuIsOpen(!menuIsOpen)
                  }}
                >
                  {
                    menuIsOpen
                      ? <CloseIcon fontSize="large" />
                      : <MenuIcon fontSize="large"/>
                  }
                </div>
                {menuIsOpen && <nav
                  className="menu-navigation"
                >
                  <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/tool">Tool</Link></li>
                    <li>
                      <Link to="/help">Help</Link>
                      <ul>
                        <li>
                          <Link to="/help/using-the-tool">Using the Tool</Link>
                        </li>
                        <li>
                          <Link to="/help/importing-into-unity">
                            Importing into Unity
                          </Link>
                        </li>
                        <li>
                          <Link to="/help/modifying-in-unity">
                            Modifying in Unity
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>}
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  </div>
}
