/**
 * Return the given value clamped between a min and a max value.
 * @param value The value to clamp.
 * @param min returned if value is lower than it.
 * @param max returned if value is higher than it.
 * @returns The clamped value.
 */
export default function clamp (value: number, min: number, max: number) {
  return value <= min
    ? min
    : value >= max
      ? max
      : value
}
