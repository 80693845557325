import { COLOUR_FOR_ITEM_TYPE_FOR_DISPLAY_MODE, PREVIEW_CIRCLE_RADIUS } from '../config'
import Camera from '../models/Camera'
import Bench from '../models/Bench'
import Frame from '../models/Frame'
import DisplayMode from '../models/DisplayMode'
import ItemType from '../models/ItemType'
import MouseData from '../models/MouseData'
import ToolMode from '../models/ToolMode'

/**
 * Draw the simple, subtle prompt for the user showing where the current tool
 * will be activated.
 * @param context The context to draw in.
 * @param mousePixel The canvas pixel location of the mouse.
 * @param toolMode The tool mode to draw the prompt for.
 */
export default function drawToolPreview (
  context: CanvasRenderingContext2D,
  mouseData: MouseData,
  toolMode: ToolMode
) {
  switch (toolMode) {
    case ToolMode.Delete:
    case ToolMode.Pan:
      // Delete needs no prompt because the thing-to-be-deleted will be
      // highlighted. Pan needs no preview because it updates whilst you use it.
      break

    // All of the following tools simply place a faint circle at the grid
    // intersection at which they will start drawing.
    case ToolMode.Wall:
    case ToolMode.LinkedWall:
    case ToolMode.Floor: {
      const closestCoordinatePixel = mouseData.nearestGrid.pixelPoint()

      const itemType =
        toolMode === ToolMode.Floor ? ItemType.Floor : ItemType.Wall

      const circle = new Path2D()
      circle.arc(
        closestCoordinatePixel.x,
        closestCoordinatePixel.y,
        PREVIEW_CIRCLE_RADIUS,
        0,
        2 * Math.PI
      )

      context.fillStyle =
        COLOUR_FOR_ITEM_TYPE_FOR_DISPLAY_MODE[itemType][DisplayMode.Preview]
      context.fill(circle)

      break
    }

    // These tools paint a preview of a particular Item.
    case ToolMode.Camera:
    case ToolMode.Bench:
    case ToolMode.Frame: {
      const itemClass = {
        [ToolMode.Camera]: Camera,
        [ToolMode.Bench]: Bench,
        [ToolMode.Frame]: Frame
      }

      const previewCamera = new itemClass[toolMode](
        mouseData.topLeftGrid,
        mouseData.nearestNonCentreInternalCellPosition
      )
      previewCamera.draw(context, DisplayMode.Preview)

      break
    }

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const test: never = toolMode
      console.error('Failed to account for all potential ToolModes')
      break
    }
  }
}
