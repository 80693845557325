import React, { Reducer, useReducer, useState, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import {
  BACKEND_URL,
  MINIMUM_INITIAL_SCALE
} from '../../config'

import ToolMode from '../../models/ToolMode'

import itemsReducer, {
  ItemsAction,
  ItemsState
} from '../../utilities/itemsReducer'
import panAndZoomReducer, {
  PanAndZoomAction,
  PanAndZoomActionType,
  PanAndZoomState
} from '../../utilities/panAndZoomReducer'
import { loadItemsOrDefault } from '../../utilities/localStorage'
import serialiseItems from '../../utilities/serialiseItems'

import Button from '../Button'
import FloorPlanDesigner from '../FloorPlanDesigner'
import LoadingBar from '../LoadingBar'
import Modal from '../Modal'
import ToolControls from '../ToolControls'
import Point from '../../models/Point'

export default function ToolPage () {
  const [toolMode, setToolMode] = useState<ToolMode>(ToolMode.Pan)
  const [itemsState, itemsDispatch] =
    useReducer<Reducer<ItemsState, ItemsAction>>(
      itemsReducer,
      loadItemsOrDefault()
    )
  const [modalState, setModalState] = useState<'loading' | 'done' | null>(null)
  const [errorMessage, setErrorMessage] = useState<ReactNode | undefined>()
  const [{ panOffset, scale }, panAndZoomDispatch] =
    useReducer<Reducer<PanAndZoomState, PanAndZoomAction>>(panAndZoomReducer, {
      panOffset: new Point(0, 0),
      scale: MINIMUM_INITIAL_SCALE,
      displayWidth: 0,
      displayHeight: 0,
      cameraTopLeftGridPoint: new Point(0, 0)
    })

  /**
   * Trigger the download of the current floor plan.
   */
  const triggerDownload = async () => {
    setModalState('loading')
    setErrorMessage(undefined)

    let response: Response | undefined
    try {
      response = await fetch(BACKEND_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: serialiseItems(itemsState)
      })
    } catch (error) {
      console.log('Fetch failed')
      console.error(error)
    }

    if (
      response === undefined ||
      (response.status >= 400 && response.status <= 499) ||
      (response.status >= 500 && response.status <= 599)
    ) {
      let errorText = ''
      try {
        if (response) {
          errorText = `Status Code: ${response.status} ${response.statusText}`
          const json = await response.json()
          if (json?.error) {
            errorText += `\nMessage: ${json.error}`
          }
        }
      } catch (error) {
        // If the endpoint didn't give back JSON, we'll get a syntax error here.
        // If that's the case we don't want to do anything, we just don't have
        // a more detailed error message.
        if (!(error instanceof SyntaxError)) throw error
      }

      setModalState('done')
      setErrorMessage(
        <>
          <p>
            Failed to get generated unity package. Please try again later or
            contact support.
          </p>
          { errorText !== '' && <p><pre>{errorText}</pre></p> }
        </>
      )
      return
    }

    const url = await response.text()

    // I resent that this seems to be the best way to force the file
    // to download without getting blocked by popup blockers.
    const a = document.createElement('a')
    a.href = url
    a.setAttribute('download', url.split('/').pop() ?? 'museum.unitypackage')
    a.click()

    setModalState('done')
  }

  return <div className="ToolPage">
    {modalState === 'loading' && <Modal title="Loading...">
      <LoadingBar />
    </Modal>}
    {modalState === 'done' && errorMessage !== undefined && <Modal
      title="Something went wrong...`"
    >
      {errorMessage}
      <div className="button-row">
        <Button onClick={() => setModalState(null)}>Back to the Tool</Button>
      </div>
    </Modal>}
    {modalState === 'done' && errorMessage === undefined && <Modal
      title="Download complete!"
    >
      <p>
        Congratulations! Your download should have now appeared.
      </p>
      <div className="button-row">
        <Button onClick={() => setModalState(null)}>Back to the Tool</Button>
        <Link to="/help/importing-into-unity">
          <Button>Help Importing Into Unity</Button>
        </Link>
        <Link to="/help/modifying-in-unity">
          <Button>Help Modifying In Unity</Button>
        </Link>
      </div>
    </Modal>}
    <FloorPlanDesigner
      items={itemsState.items}
      selectedItemData={itemsState.selectedItemData}
      hoveredItemData={itemsState.hoveredItemData}
      hoveredResizeNode={itemsState.hoveredResizeNode}
      camera={itemsState.camera}
      itemsDispatch={itemsDispatch}
      panOffset={panOffset}
      scale={scale}
      panAndZoomDispatch={panAndZoomDispatch}
      toolMode={toolMode}
    />
    <ToolControls
      toolMode={toolMode}
      setToolMode={setToolMode}
      resetZoom={() => panAndZoomDispatch({ type: PanAndZoomActionType.Reset })}
      triggerDownload={triggerDownload}
    />
  </div>
}
