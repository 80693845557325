import { CANVAS_HEIGHT, CANVAS_WIDTH } from '../config'
import clamp from '../utilities/clamp'
import { getNearestInternalCellPosition, getNearestInternalCellPositionThatIsNotCentre } from '../utilities/getNearestInternalCellPosition'
import InternalCellPosition from './InternalCellPosition'
import Point from './Point'

export default class MouseData {
  // Note that these properties, based on actual pixel coordinates, are not
  // considered in the function for checking equality.. MouseData objects can
  // have different pixels values, but if they are the same otherwise they are
  // considered equal, to avoid updating things when they don't need to be
  // updated.
  displayedMousePixel: Point
  mousePixel: Point

  onFloorPlan: boolean
  nearestGrid: Point
  topLeftGrid: Point
  nearestInternalCellPosition: InternalCellPosition
  nearestNonCentreInternalCellPosition:
    Exclude<InternalCellPosition, InternalCellPosition.Centre>

  /**
   * Create a mouse data object for starting out.
   */
  static makeInitialMouseData (): MouseData {
    return new this(
      { getBoundingClientRect: () => ({ left: 0, top: 0 }) },
      { clientX: 0, clientY: 0 },
      new Point(0, 0),
      1
    )
  }

  /**
   * Create a new MouseData object. The types of displayedCanvas and mouseEvent
   * are stripped down to the bare requirements to make it easier to create an
   * initial mouse data state.
   * @param displayedCanvas The HTML canvas object that is actually rendered in
   * the DOM, to be used to help place the mouse in the floor plan canvas.
   * @param mouseEvent The event that was triggered for this construction of
   * mouse data, for getting the mouse's actual position on the page.
   * @param panOffset The current pan offset, to work out where on the displayed
   * canvas the floor plan canvas is.
   * @param scale The current scale, to work out how large on the displayed
   * canvas the floor plan canvas is.
   */
  constructor (
    // HTMLCanvasElement.
    displayedCanvas:
      { getBoundingClientRect: () => ({left: number, top: number }) },
    // MouseEvent.
    mouseEvent: { clientX: number, clientY: number },
    panOffset: Point,
    scale: number
  ) {
    const { left, top } = displayedCanvas.getBoundingClientRect()

    this.displayedMousePixel =
      new Point(mouseEvent.clientX - left, mouseEvent.clientY - top)

    // This assumes that the displayed canvas will have the same size on the
    // page as it has size in css (like in actual pixels on the user's
    // screen), which should be.

    const unclampedPixel: Point =
      this.displayedMousePixel.minus(panOffset).times(1 / scale)

    this.mousePixel = new Point(
      clamp(unclampedPixel.x, 0, CANVAS_WIDTH),
      clamp(unclampedPixel.y, 0, CANVAS_HEIGHT)
    )

    this.onFloorPlan = unclampedPixel.equals(this.mousePixel)
    this.nearestGrid = this.mousePixel.nearestGridPoint()
    this.topLeftGrid = this.mousePixel.topLeftGridPoint()
    this.nearestInternalCellPosition =
      getNearestInternalCellPosition(this.mousePixel)
    this.nearestNonCentreInternalCellPosition =
      getNearestInternalCellPositionThatIsNotCentre(this.mousePixel)
  }

  /**
   * Check if two different mouse data objects represent the same information.
   * @param otherMouseData The other mouse data object to check against.
   * @returns True if the represent the same information, false otherwise.
   */
  equals (
    otherMouseData: MouseData
  ): boolean {
    return this.onFloorPlan === otherMouseData.onFloorPlan &&
      this.nearestGrid.equals(otherMouseData.nearestGrid) &&
      this.topLeftGrid.equals(otherMouseData.topLeftGrid) &&
      this.nearestInternalCellPosition ===
        otherMouseData.nearestInternalCellPosition &&
      this.nearestNonCentreInternalCellPosition ===
        otherMouseData.nearestNonCentreInternalCellPosition
  }
}
