enum InternalCellPosition {
  Top = 'Top',
  Left = 'Left',
  Centre = 'Centre',
  Right = 'Right',
  Bottom = 'Bottom'
}

export default InternalCellPosition

export type Direction = Exclude<InternalCellPosition, 'Centre'>
