import DisplayMode from '../models/DisplayMode'
import ItemType from '../models/ItemType'

export { BACKEND_URL } from './backendURL'

// Canvas
export const CANVAS_WIDTH = 2000
export const CANVAS_HEIGHT = 2000
export const NUMBER_OF_CELLS_WIDE = 20
export const NUMBER_OF_CELLS_HIGH = 20
export const X_INCREMENT_WIDTH = Math.floor(CANVAS_WIDTH / NUMBER_OF_CELLS_WIDE)
export const Y_INCREMENT_HEIGHT =
  Math.floor(CANVAS_HEIGHT / NUMBER_OF_CELLS_HIGH)
export const PREVIEW_CIRCLE_RADIUS = 10
export const PREVIEW_OPACITY = 0.25

// Colours
export const ORANGE = 'rgba(255, 170, 0, 0.8)'
// Note that some of these ItemType, DisplayMode combinations never happen. We
// should consider re-working this model.
export const COLOUR_FOR_ITEM_TYPE_FOR_DISPLAY_MODE: Record<
  ItemType,
  Record<DisplayMode, string>
> = {
  [ItemType.Wall]: {
    [DisplayMode.New]: 'rgba(95, 95, 95, 1)',
    [DisplayMode.Normal]: 'black',
    [DisplayMode.Hovered]: 'rgba(95, 95, 95, 1)',
    [DisplayMode.Selected]: 'rgba(95, 95, 95, 1)',
    [DisplayMode.Preview]: `rgba(0, 0, 0, ${PREVIEW_OPACITY})`
  },
  [ItemType.Floor]: {
    [DisplayMode.New]: 'rgba(145, 145, 145, 0.75)',
    [DisplayMode.Normal]: 'rgba(233, 233, 233, 0.75)',
    [DisplayMode.Hovered]: 'rgba(189, 189, 189, 0.75)',
    [DisplayMode.Selected]: 'rgba(189, 189, 189, 0.75)',
    [DisplayMode.Preview]: `rgba(0, 0, 0, ${PREVIEW_OPACITY})`
  },
  [ItemType.Camera]: {
    [DisplayMode.New]: 'black',
    [DisplayMode.Normal]: 'black',
    [DisplayMode.Hovered]: 'black',
    [DisplayMode.Selected]: 'black',
    [DisplayMode.Preview]: `rgba(0, 0, 0, ${PREVIEW_OPACITY})`
  },
  [ItemType.Bench]: {
    [DisplayMode.New]: ORANGE,
    [DisplayMode.Normal]: 'rgba(80, 35, 20, 1)',
    [DisplayMode.Hovered]: ORANGE,
    [DisplayMode.Selected]: ORANGE,
    [DisplayMode.Preview]: `rgba(80, 35, 20, ${PREVIEW_OPACITY})`
  },
  [ItemType.Frame]: {
    [DisplayMode.New]: ORANGE,
    [DisplayMode.Normal]: 'rgba(121, 121, 121, 1)',
    [DisplayMode.Hovered]: ORANGE,
    [DisplayMode.Selected]: ORANGE,
    [DisplayMode.Preview]: `rgba(0, 0, 0, ${PREVIEW_OPACITY})`
  }
}

// Floor and Walls
export const NORMAL_LINE_DASH = []
export const WALL_LINE_WIDTH = 4
export const FLOOR_LINE_WIDTH = 2
export const FLOOR_STROKE_COLOUR = 'black'
export const FLOOR_LINE_DASH = [8, 8]
export const RESIZE_NODE_COLOUR = ORANGE
export const RESIZE_NODE_RADIUS = 10

// Benches and Frames
const minimumIncrement = Math.min(X_INCREMENT_WIDTH, Y_INCREMENT_HEIGHT)
export const FRAME_INSET = Math.floor((4 / 64) * minimumIncrement)
export const FRAME_LINE_WIDTH = Math.floor((4 / 64) * minimumIncrement)
export const FRAME_WIDTH = Math.floor((2 / 5) * minimumIncrement)
export const BENCH_INSET = Math.floor(2.5 * (4 / 64) * minimumIncrement)
export const BENCH_LINE_WIDTH = Math.floor((4 / 64) * minimumIncrement)
export const BENCH_WIDTH = Math.floor((3 / 5) * minimumIncrement)

// Zoom
export const SCALE_INCREMENT = 0.0625
export const MINIMUM_SCALE = 0.5
export const MAXIMUM_SCALE = 3
export const MINIMUM_INITIAL_SCALE = 1.5
