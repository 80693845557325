/**
 * This file provides functions for handling the loading and saving of an
 * ItemsState, which should summarise the item state of the floor plan, to and
 * from local storage. To allow it to persist across page navigation and even
 * leaving and returning to the website.
 */

import { z, ZodError } from 'zod'

import Camera from '../models/Camera'
import InternalCellPosition, { Direction } from '../models/InternalCellPosition'
import Floor from '../models/Floor'
import Wall from '../models/Wall'
import Bench from '../models/Bench'
import Frame from '../models/Frame'

import Item from '../models/Item'
import Point from '../models/Point'

import { NUMBER_OF_CELLS_HIGH, NUMBER_OF_CELLS_WIDE } from '../config'

import { ItemsState, makeLocationKey } from './itemsReducer'
import serialiseItems from './serialiseItems'

const LOCAL_STORAGE_KEY = 'itemsState'

const localStorage = window.localStorage

export class LocalStorageError extends Error {}

const pointScheama = z.object({
  x: z.number().int().nonnegative(),
  y: z.number().int().nonnegative()
})
const rotationSchema = z.union([
  z.literal('up'),
  z.literal('down'),
  z.literal('left'),
  z.literal('right')
])
const assetNameSchema = z.union([z.literal('bench'), z.literal('frame')])
const positionSchema = z.union([
  z.literal('top'),
  z.literal('bottom'),
  z.literal('left'),
  z.literal('right')
])
const deserialisedItemsSchema = z.object({
  floors: z.array(z.tuple([pointScheama, pointScheama])),
  walls: z.array(z.tuple([pointScheama, pointScheama])),
  camera: pointScheama.extend({
    rot: rotationSchema
  }),
  assets: z.array(pointScheama.extend({
    name: assetNameSchema,
    position: positionSchema
  }))
})

/**
 * Save a given items state to local storage, for later loading.
 * @param itemsState The items state to save.
 */
export function saveItems (itemsState: ItemsState): void {
  const jsonString = serialiseItems(itemsState)
  localStorage.setItem(LOCAL_STORAGE_KEY, jsonString)
}

/**
 * Attempt to load the items state from local storage, but if it fails just
 * return a default starting value.
 * @returns An items state, either one loaded from local storage, or an empty
 * starting one.
 */
export function loadItemsOrDefault (): ItemsState {
  try {
    return loadItems()
  } catch (error) {
    if (
      !(error instanceof LocalStorageError) &&
      !(error instanceof ZodError)
    ) {
      throw error
    }

    console.error(error)
  }

  console.log('Resorting to default items state.')
  return {
    items: {},
    nextItemIndex: 0,
    selectedItemData: undefined,
    hoveredItemData: undefined,
    hoveredResizeNode: undefined,
    benchLocations: new Set<string>(),
    frameLocations: new Set<string>(),
    camera: new Camera(
      new Point(NUMBER_OF_CELLS_WIDE / 2, NUMBER_OF_CELLS_HIGH / 2),
      InternalCellPosition.Right
    )
  }
}

/**
 * Attempt to load the items state from local storage, erroring if it can't.
 * @returns The loaded items state.
 * @throws {LocalStorageError} If there was nothing at the key, or the object at
 * the key failed to JSON.parse.
 * @throws {ZodError} If the parsed JSON from local storage failed the Zod
 * schema validation.
 */
export function loadItems (): ItemsState {
  const jsonString = localStorage.getItem(LOCAL_STORAGE_KEY)

  if (jsonString === null) {
    throw new LocalStorageError('Unable to find items state in local storage')
  }

  let parsedJSON: unknown
  try {
    parsedJSON = JSON.parse(jsonString)
  } catch (error) {
    if (!(error instanceof SyntaxError)) throw error

    throw new LocalStorageError(
      'Unable to parse items state in local storage as JSON'
    )
  }

  const deserialisedItems = deserialisedItemsSchema.parse(parsedJSON)

  const items: Record<string, Item> = {}
  let nextItemIndex = 0
  const benchLocations = new Set<string>()
  const frameLocations = new Set<string>()

  for (const item of deserialisedItems.floors) {
    items[nextItemIndex++] = new Floor(
      new Point(item[0].x, item[0].y).toggleBackendCoordinates(),
      new Point(item[1].x, item[1].y).toggleBackendCoordinates()
    )
  }

  for (const item of deserialisedItems.walls) {
    items[nextItemIndex++] = new Wall(
      new Point(item[0].x, item[0].y).toggleBackendCoordinates(),
      new Point(item[1].x, item[1].y).toggleBackendCoordinates()
    )
  }

  const positionMapping: Record<z.infer<typeof positionSchema>, Direction> = {
    top: InternalCellPosition.Top,
    bottom: InternalCellPosition.Bottom,
    left: InternalCellPosition.Left,
    right: InternalCellPosition.Right
  }

  const assetMapping: Record<
    z.infer<typeof assetNameSchema>,
    { AssetClass: typeof Bench | typeof Frame, locationsSet: Set<string>}
  > = {
    bench: { AssetClass: Bench, locationsSet: benchLocations },
    frame: { AssetClass: Frame, locationsSet: frameLocations }
  }

  for (const assetData of deserialisedItems.assets) {
    const item = new assetMapping[assetData.name].AssetClass(
      // Still in backend coordinates, swap from bottom left to top left.
      new Point(assetData.x, assetData.y + 1).toggleBackendCoordinates(),
      positionMapping[assetData.position]
    )
    const locationKey = makeLocationKey(item)

    // Don't double up on assets of the same type in the same place, even if it
    // is in the data.
    if (!assetMapping[assetData.name].locationsSet.has(locationKey)) {
      items[nextItemIndex++] = item
      assetMapping[assetData.name].locationsSet.add(makeLocationKey(item))
    }
  }

  const rotationMapping: Record<z.infer<typeof rotationSchema>, Direction> = {
    up: InternalCellPosition.Top,
    down: InternalCellPosition.Bottom,
    left: InternalCellPosition.Left,
    right: InternalCellPosition.Right
  }

  return {
    items,
    nextItemIndex,
    selectedItemData: undefined,
    hoveredItemData: undefined,
    hoveredResizeNode: undefined,
    benchLocations,
    frameLocations,
    camera: new Camera(
      new Point(
        deserialisedItems.camera.x,
        // Still in backend coordinates, swap from bottom left to top left.
        deserialisedItems.camera.y + 1
      ).toggleBackendCoordinates(),
      rotationMapping[deserialisedItems.camera.rot]
    )
  }
}
