import Bench from '../models/Bench'
import Camera from '../models/Camera'
import Frame from '../models/Frame'
import Item from '../models/Item'
import MouseData from '../models/MouseData'
import OptionalItemData, {
  optionalItemDatasMatch
} from '../models/OptionalItemData'
import ResizeNode from '../models/ResizeNode'
import ToolMode from '../models/ToolMode'

import getHoveredItemData from './getHoveredItemData'
import { saveItems } from './localStorage'

export type ItemsState = {
  // A keyed record rather than an array such that all elements keep their index
  // even when one is deleted. A string key instead of a number key because all
  // keys are strings and so they come out of Object.entries that way.
  items: Record<string, Item>
  nextItemIndex: number
  selectedItemData: OptionalItemData
  // This hovered item data will update even if the user is currently creating /
  // editing something (indeed this whole reducer-state-action-whatever system
  // has no notion of that being a thing), the actual component should just
  // ignore it in that case.
  hoveredItemData: OptionalItemData
  hoveredResizeNode: ResizeNode | undefined
  benchLocations: Set<string>
  frameLocations: Set<string>
  camera: Camera
}

function statesMatch (
  stateOne: ItemsState,
  stateTwo: ItemsState
): boolean {
  // If they are literally the same object don't bother checking everything
  // else.
  if (stateOne === stateTwo) return true

  // Note that some objects are just being compared. These objects _should_ be
  // overwritten rather than mutated when they are changed.
  return stateOne.items === stateTwo.items &&
    stateOne.nextItemIndex === stateTwo.nextItemIndex &&
    optionalItemDatasMatch(
      stateOne.selectedItemData,
      stateTwo.selectedItemData
    ) &&
    optionalItemDatasMatch(
      stateOne.hoveredItemData,
      stateTwo.hoveredItemData
    ) &&
    stateOne.hoveredResizeNode === stateTwo.hoveredResizeNode &&
    stateOne.benchLocations === stateTwo.benchLocations &&
    stateOne.frameLocations === stateTwo.frameLocations &&
    stateOne.camera === stateTwo.camera
}

/**
 * Given a bench or frame, get a string key unique to that location that can be
 * used to identify it.
 * @param item The bench or frame.
 * @returns The string key identifying the location.
 */
export function makeLocationKey (item: Bench | Frame): string {
  return `${item.topLeftGridPoint.x}-${item.topLeftGridPoint.y}-` +
    item.direction
}

export enum ItemsActionType {
  // Add a given item.
  Add = 'Add',
  // Delete the item that is currently selected.
  DeleteSelected = 'DeleteSelected',
  // Delete the item that is currently hovered.
  DeleteHovered = 'DeleteHovered',
  // Set the currently selected item to be the currently hovered item.
  SelectHovered = 'SelectHovered',
  // Clear the selection.
  Deselect = 'Deselect',
  // Register a mouse move, and update the hovered data appropriately.
  MoveMouse = 'MoveMouse',
  // Update the camera.
  SetCamera = 'SetCamera'
}

export type ItemsAction =
  { type: ItemsActionType.Add, item: Item, editing: boolean } |
  { type: ItemsActionType.DeleteSelected } |
  { type: ItemsActionType.DeleteHovered } |
  { type: ItemsActionType.SelectHovered } |
  { type: ItemsActionType.Deselect } |
  {
    type: ItemsActionType.MoveMouse,
    displayedContext: CanvasRenderingContext2D,
    mouseData: MouseData,
    toolMode: ToolMode
  } |
  { type: ItemsActionType.SetCamera, newCamera: Camera }

/**
 * A function for managing the state around Items that may appear on the canvas.
 * @param state The current state.
 * @param action The action being performed.
 * @returns The new modified state, or the current state if the updated state
 * would be identical.
 */
export default function itemsReducer (
  state: ItemsState,
  action: ItemsAction
): ItemsState {
  // I don't assign it here such that TypeScript will validate every route has
  // been handled by requiring it to be initialised.
  let newState: ItemsState

  switch (action.type) {
    case ItemsActionType.Add: {
      // Ensure that there is only one bench or frame in each location.

      // These need to be the same actual objects unless they are changed, so
      // that comparisons of state work.
      let newBenchLocations = state.benchLocations
      let newFrameLocations = state.frameLocations

      if (action.item instanceof Bench) {
        const locationKey = makeLocationKey(action.item)

        if (state.benchLocations.has(locationKey)) {
          newState = state
          break
        }

        newBenchLocations = new Set(newBenchLocations)
        newBenchLocations.add(locationKey)
      }

      if (action.item instanceof Frame) {
        const locationKey = makeLocationKey(action.item)

        if (state.frameLocations.has(locationKey)) {
          newState = state
          break
        }

        newFrameLocations = new Set(newFrameLocations)
        newFrameLocations.add(locationKey)
      }

      // Add the item to the new state.

      newState = {
        ...state,
        items: { ...state.items, [state.nextItemIndex]: action.item },
        nextItemIndex: state.nextItemIndex + 1,
        // If we are editing we selected the just "created" item.
        selectedItemData: action.editing
          ? { item: action.item, index: '' + state.nextItemIndex }
          : state.selectedItemData,
        benchLocations: newBenchLocations,
        frameLocations: newFrameLocations
      }

      break
    }

    case ItemsActionType.DeleteSelected:
    case ItemsActionType.DeleteHovered: {
      // This will do nothing if the item to be deleted doesn't exist.

      const deleteSelected = action.type === ItemsActionType.DeleteSelected

      const indexToDelete = deleteSelected
        ? state.selectedItemData?.index
        : state.hoveredItemData?.index

      if (indexToDelete === undefined) {
        newState = state
        break
      }

      // If it is a bench of frame remove it's location from the sets tracking
      // that.
      let newBenchLocations = state.benchLocations
      let newFrameLocations = state.frameLocations
      const item = state.items[indexToDelete]
      if (item !== undefined) {
        if (item instanceof Bench) {
          newBenchLocations = new Set(newBenchLocations)
          newBenchLocations.delete(makeLocationKey(item))
        }

        if (item instanceof Frame) {
          newFrameLocations = new Set(newFrameLocations)
          newFrameLocations.delete(makeLocationKey(item))
        }
      }

      const newItems = { ...state.items }
      delete newItems[indexToDelete]
      newState = {
        ...state,
        items: newItems,
        // If we have deleted the selected item, we don't want it to stay
        // selected.
        selectedItemData: deleteSelected ? undefined : state.selectedItemData,
        benchLocations: newBenchLocations,
        frameLocations: newFrameLocations
      }

      break
    }

    case ItemsActionType.SelectHovered:
      newState = { ...state, selectedItemData: state.hoveredItemData }

      break

    case ItemsActionType.Deselect:
      newState = { ...state, selectedItemData: undefined }

      break

    case ItemsActionType.MoveMouse: {
      const newHoveredItemData = getHoveredItemData(
        action.displayedContext,
        action.mouseData.mousePixel,
        state.items,
        action.toolMode
      )

      let newHoveredResizeNode: ResizeNode | undefined
      if (state.selectedItemData !== undefined) {
        for (const resizeNode of state.selectedItemData.item.resizeNodes) {
          if (resizeNode.containsPixelPoint(
            action.displayedContext,
            action.mouseData.mousePixel
          )) {
            newHoveredResizeNode = resizeNode
            break
          }
        }
      }

      newState = {
        ...state,
        hoveredItemData: newHoveredItemData,
        hoveredResizeNode: newHoveredResizeNode
      }

      break
    }

    case ItemsActionType.SetCamera:
      newState = { ...state, camera: action.newCamera }

      break
  }

  // Update the state, if it has changed. If it hasn't, set it back to the
  // original state object so that we don't get a needless update / re-render.
  if (statesMatch(state, newState)) {
    return state
  } else {
    saveItems(newState)
    return newState
  }
}
