export const cameraBottom = document.createElement('img')
export const cameraLeft = document.createElement('img')
export const cameraRight = document.createElement('img')
export const cameraTop = document.createElement('img')

function makePromiseOfLoaded (element: HTMLImageElement): Promise<void> {
  return new Promise((resolve, reject) => {
    element.addEventListener('load', () => resolve())
    element.addEventListener('error', reject)
  })
}

export const promiseOfLoaded = Promise.all([
  makePromiseOfLoaded(cameraBottom),
  makePromiseOfLoaded(cameraLeft),
  makePromiseOfLoaded(cameraRight),
  makePromiseOfLoaded(cameraTop)
])

// Just down here to make sure I assign the event listeners / create the
// promises before the load event actually happens.
cameraTop.src = '/assets/camera_top.png'
cameraBottom.src = '/assets/camera_bottom.png'
cameraLeft.src = '/assets/camera_left.png'
cameraRight.src = '/assets/camera_right.png'
