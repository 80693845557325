import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../Button'

import './styles.css'

export default function HelpCards () {
  return <div className="HelpCards">
    <Link to="/help/using-the-tool" className="card">
      <h2>Using the Tool</h2>
      <p>Guidance on how to use the floor plan tool itself.</p>
      <Button>Read the Docs</Button>
    </Link>
    <Link to="/help/importing-into-unity" className="card">
      <h2>Importing into Unity</h2>
      <p>
        The simple instructions for getting your exported floor plan into
        Unity.
      </p>
      <Button>Read the Docs</Button>
    </Link>
    <Link to="/help/modifying-in-unity" className="card">
      <h2>Modifying in Unity</h2>
        <p>
          Details of how the tool builds the scene in Unity, and how you can
          manipulate it.
        </p>
        <Button>Read the Docs</Button>
    </Link>
  </div>
}
