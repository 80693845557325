import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  MAXIMUM_SCALE,
  MINIMUM_INITIAL_SCALE,
  MINIMUM_SCALE,
  X_INCREMENT_WIDTH,
  Y_INCREMENT_HEIGHT,
  SCALE_INCREMENT
} from '../config'

import Point from '../models/Point'

import clamp from './clamp'

export type PanAndZoomState = {
  panOffset: Point
  scale: number
  displayWidth: number
  displayHeight: number
  cameraTopLeftGridPoint: Point
}

function statesMatch (
  stateOne: PanAndZoomState,
  stateTwo: PanAndZoomState
): boolean {
  return stateOne.panOffset.equals(stateTwo.panOffset) &&
    stateOne.scale === stateTwo.scale &&
    stateOne.displayWidth === stateTwo.displayWidth &&
    stateOne.displayHeight === stateTwo.displayHeight &&
    stateOne.cameraTopLeftGridPoint.equals(stateTwo.cameraTopLeftGridPoint)
}

export enum PanAndZoomActionType {
  ZoomIn = 'ZoomIn',
  ZoomOut = 'ZoomOut',
  Pan = 'Pan',
  Update = 'Update',
  Reset = 'Reset'
}

export type PanAndZoomAction =
  {
    type: PanAndZoomActionType.ZoomIn | PanAndZoomActionType.ZoomOut
    // The pixel coordinate of the mouse on the displayed canvas.
    displayedMousePixel: Point
  } |
  {
    type: PanAndZoomActionType.Pan
    // Same meaning as above.
    displayedMousePixel: Point
    actionGridStart: Point
  } |
  {
    type: PanAndZoomActionType.Update
    displayWidth?: number
    displayHeight?: number
    cameraTopLeftGridPoint?: Point
  } |
  { type: PanAndZoomActionType.Reset }

export default function panAndZoomReducer (
  state: PanAndZoomState,
  action: PanAndZoomAction
): PanAndZoomState {
  let newState: PanAndZoomState

  switch (action.type) {
    case PanAndZoomActionType.ZoomIn:
    case PanAndZoomActionType.ZoomOut: {
      // Increment or decrement the scale once, and then update the pan offset
      // to keep the mouse over the same part of the floor plan.

      // The pixel coordinate of the mouse over the floor plan canvas (distinct
      // from the pixel coordinate of the mouse _in_ the floor plan canvas, as
      // this doesn't account for the current scale).
      const floorPlanPixel = action.displayedMousePixel.minus(state.panOffset)

      const zoomingIn = action.type === PanAndZoomActionType.ZoomIn
      const newScale = clamp(
        state.scale + (zoomingIn ? 1 : -1) * SCALE_INCREMENT,
        MINIMUM_SCALE,
        MAXIMUM_SCALE
      )

      const scalingFactor = newScale / state.scale

      // Decrease the pan offset by the amount the floor plan vector increased.
      const newPanOffset =
        state.panOffset.minus(floorPlanPixel.times(scalingFactor - 1))

      newState = { ...state, scale: newScale, panOffset: newPanOffset }

      break
    }

    case PanAndZoomActionType.Pan: {
      // Pan so that the pixel of the selected grid is under the current mouse
      // position.

      const gridPixel = action.actionGridStart.pixelPoint().times(state.scale)

      const newPanOffset = action.displayedMousePixel.minus(gridPixel)

      newState = { ...state, panOffset: newPanOffset }

      break
    }

    case PanAndZoomActionType.Update: {
      // Simply update any of the data that this reducer needs to track. The
      // changes to the display size might require the panOffset to be reset to
      // keep the canvas on the page, but those adjustments will be made after
      // this, the same way it is for every other action.

      newState = {
        ...state,
        displayWidth: action.displayWidth ?? state.displayWidth,
        displayHeight: action.displayHeight ?? state.displayHeight,
        cameraTopLeftGridPoint:
          action.cameraTopLeftGridPoint ?? state.cameraTopLeftGridPoint
      }

      break
    }

    case PanAndZoomActionType.Reset: {
      // Setup / reset the pan and zoom as if for first time display. This will
      // set the scale such that if the pan was centred the floor plan canvas
      // would cover the entire screen, and set the pan such that the camera is
      // centred.
      const scaleToFillX = state.displayWidth / CANVAS_WIDTH
      const scaleToFillY = state.displayHeight / CANVAS_HEIGHT

      // Start the new scale at the minimum scale needed to fill the display.
      let newScale = Math.max(scaleToFillX, scaleToFillY)
      // Clamp the new scale to the allowed zoom levels.
      newScale = clamp(newScale, MINIMUM_INITIAL_SCALE, MAXIMUM_SCALE)
      // Fit the new scale to the nearest zoom increment. Ceil rather than floor
      // so that it still fills the display.
      newScale = Math.ceil(newScale / SCALE_INCREMENT) * SCALE_INCREMENT

      // Offsetting by the exact negative of position of the centre of the
      // camera in the floor plan canvas in real pixel coordinates should centre
      // the centre of the camera on the very top left of the screen. Adding
      const newPanOffset = state.cameraTopLeftGridPoint
        // Get the pixel coordinate in the floor plan canvas.
        .pixelPoint()
        // Add half a cell to get the centre of the cell.
        .plus(new Point(X_INCREMENT_WIDTH / 2, Y_INCREMENT_HEIGHT / 2))
        // Scale that up to the displayed canvas and take the negative to move
        // out the top right corner this amount, putting the camera exactly on
        // the corner.
        .times(-newScale)
        // Add half the screen to centre it in the middle.
        .plus(new Point(state.displayWidth / 2, state.displayHeight / 2))

      newState = {
        ...state,
        panOffset: newPanOffset,
        scale: newScale
      }

      break
    }
  }

  // Clamp the panOffset such that there are always at least two rows or columns
  // of cells on the display after these changes.

  const minimumXOffset =
    newState.scale * (-CANVAS_WIDTH + 2 * X_INCREMENT_WIDTH)
  const maximumXOffset =
    newState.displayWidth - newState.scale * 2 * X_INCREMENT_WIDTH
  const minimumYOffset =
    newState.scale * (-CANVAS_HEIGHT + 2 * Y_INCREMENT_HEIGHT)
  const maximumYOffset =
    newState.displayHeight - newState.scale * 2 * Y_INCREMENT_HEIGHT

  newState = {
    ...newState,
    panOffset: new Point(
      clamp(newState.panOffset.x, minimumXOffset, maximumXOffset),
      clamp(newState.panOffset.y, minimumYOffset, maximumYOffset)
    )
  }

  // Update the state, if it has changed. If it hasn't, set it back to the
  // original state object so that we don't get a needless re-render.
  if (statesMatch(state, newState)) {
    return state
  } else {
    return newState
  }
}
