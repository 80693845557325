import React, {
  useRef,
  useEffect,
  useState,
  Dispatch
} from 'react'

import {
  CANVAS_WIDTH,
  CANVAS_HEIGHT
} from '../../config'

import gridCanvas from '../../utilities/gridCanvas'
import drawToolPreview from '../../utilities/drawToolPreview'
import {
  PanAndZoomAction,
  PanAndZoomActionType
} from '../../utilities/panAndZoomReducer'
import { ItemsAction, ItemsActionType } from '../../utilities/itemsReducer'
import { promiseOfLoaded } from '../../utilities/images'

import Point from '../../models/Point'
import ToolMode from '../../models/ToolMode'
import Wall from '../../models/Wall'
import Floor from '../../models/Floor'
import DisplayMode from '../../models/DisplayMode'
import ResizeNode from '../../models/ResizeNode'
import OptionalItemData from '../../models/OptionalItemData'
import Item from '../../models/Item'
import Camera from '../../models/Camera'
import MouseData from '../../models/MouseData'
import Bench from '../../models/Bench'
import Frame from '../../models/Frame'

import './styles.css'

// TODO: Dragging whole items.
// TODO: Decide if context should be taken by items when they are constructed.

type ActionStartData = {
  grid: Point
  editing: boolean
}

type Props = {
  items: Record<string, Item>
  selectedItemData: OptionalItemData
  hoveredItemData: OptionalItemData
  hoveredResizeNode: ResizeNode | undefined
  camera: Camera
  itemsDispatch: Dispatch<ItemsAction>
  panOffset: Point
  scale: number
  panAndZoomDispatch: Dispatch<PanAndZoomAction>
  toolMode: ToolMode
}

/**
 * This is the floor plan designer component, which provides the core
 * functionality of the webpage. This component, more or less, _is_ our frontend
 * application (though of course a bunch of details are abstracted to
 * elsewhere).
 */
export default function FloorPlanDesigner ({
  items,
  selectedItemData,
  hoveredItemData,
  hoveredResizeNode,
  camera,
  itemsDispatch,
  panOffset,
  scale,
  panAndZoomDispatch,
  toolMode
}: Props) {
  const [actionStartData, setActionStartData] =
    useState<ActionStartData | null>(null)
  const [canvas, setCanvas] = useState(document.createElement('canvas'))
  const [mouseData, setMouseData] =
    useState<MouseData>(MouseData.makeInitialMouseData())
  const [imagesLoaded, setImagesLoaded] = useState(false)

  const displayedCanvasReference = useRef<HTMLCanvasElement>(null)

  // This is kind of an extension of actionStartData being null. Sometimes we
  // want to know if we can / should ignore anything we are drawing. This is
  // true if it is null, _or_ if it is of zero size.
  const drawingIsUnviable =
    // If we haven't started drawing something, we can't draw. Even for the one
    // click things, this will have been set when the mouse went down.
    actionStartData === null ||
    // If we are in a multi-cell tool, and the drawing doesn't span multiple
    // cells, then the drawing is unviable.
    (
      [ToolMode.Wall, ToolMode.LinkedWall, ToolMode.Floor].includes(toolMode) &&
      actionStartData.grid.equals(mouseData.nearestGrid)
    ) ||
    // If we are in a single-cell tool (other than the camera, for which
    // "drawing" is always viable) and we have hovered something, we can't draw.
    ([ToolMode.Bench, ToolMode.Frame].includes(toolMode) && hoveredItemData)

  // If we are currently drawing something, we don't want to treat the thing
  // we are above as being hovered. This says "visibly" but this should be the
  // only hovered item data used for display _OR FUNCTIONALITY_ purposes in
  // this component.
  const visiblyHoveredItemData: OptionalItemData =
    drawingIsUnviable ? hoveredItemData : undefined

  // Update the imagesLoaded state variable when the images are loaded, so that
  // it's change can trigger a one-time redraw.
  useEffect(
    () => { promiseOfLoaded.then(() => setImagesLoaded(true)) },
    []
  )

  // Reset any pending stuff when the tool is changed (itemsDispatch shouldn't
  // change).
  useEffect(
    () => {
      setActionStartData(null)
      itemsDispatch({ type: ItemsActionType.Deselect })
    },
    [toolMode, itemsDispatch]
  )

  // Keep the pan and zoom reducer's copy of the camera position up to date.
  useEffect(
    () => {
      panAndZoomDispatch({
        type: PanAndZoomActionType.Update,
        cameraTopLeftGridPoint: camera.topLeftGridPoint
      })
    },
    [panAndZoomDispatch, camera]
  )

  // Run the startup-only effects in their own effect because they will never
  // change over the life time of the component.
  useEffect(
    () => {
      const displayedCanvas = displayedCanvasReference.current
      const displayedContext = displayedCanvas?.getContext('2d') ?? null
      if (displayedCanvas === null || displayedContext === null) {
        console.error(
          'Failed to get displayed canvas or its context. Cannot setup ' +
          'the long-lived event listeners.'
        )
        return
      }

      // Set the size of the display canvas to match it's on-screen size and
      // update the pan and zoom handling accordingly.
      displayedCanvas.width = displayedCanvas.clientWidth
      displayedCanvas.height = displayedCanvas.clientHeight
      panAndZoomDispatch({
        type: PanAndZoomActionType.Update,
        displayWidth: displayedCanvas.width,
        displayHeight: displayedCanvas.height
      })
      panAndZoomDispatch({ type: PanAndZoomActionType.Reset })

      const removeAllInProgressThings = () => {
        setActionStartData(null)
        itemsDispatch({ type: ItemsActionType.Deselect })
      }

      // Remove all in-progress things on right-click (up)
      const mouseUpListener = (event: MouseEvent) => {
        if (event.button === 2) removeAllInProgressThings()
      }
      displayedCanvas.addEventListener('mouseup', mouseUpListener)

      const keydownListener = (event: KeyboardEvent) => {
        if (event.code === 'Delete') {
          // Delete the selected item when the delete key is pressed.
          event.preventDefault()
          itemsDispatch({ type: ItemsActionType.DeleteSelected })
        } else if (event.code === 'Escape') {
          // Remove all in-progress things when the escape key is pressed.
          event.preventDefault()
          removeAllInProgressThings()
        }
      }
      document.addEventListener('keydown', keydownListener)

      // Disable the context menu so it doesn't get in the way of that.
      const contextMenuListener = (event: Event) => {
        event.preventDefault()
      }
      displayedCanvas.addEventListener('contextmenu', contextMenuListener)

      // Remove the listeners on unmount.
      return () => {
        displayedCanvas.removeEventListener('mouseup', mouseUpListener)
        displayedCanvas.removeEventListener('contextmenu', contextMenuListener)
        document.removeEventListener('keydown', keydownListener)
      }
    },
    [
      // These are reducer dispatchers, and so should never change. React's
      // linter's static analysis just hasn't worked that out because they are
      // from another file, so I've put it in here to satiate it.
      itemsDispatch,
      panAndZoomDispatch
    ]
  )

  // Set up the mouse tracking, so we can update state only when something
  // displayed would actually change.
  useEffect(
    () => {
      const displayedCanvas = displayedCanvasReference.current
      const displayedContext = displayedCanvas?.getContext('2d') ?? null
      if (displayedCanvas === null || displayedContext === null) {
        console.error(
          'Failed to get displayed canvas or its context. Cannot setup ' +
          'the mouse movement event listener.'
        )
        return
      }

      // Track mouse movements on the canvas.
      const mouseMoveListener = (event: MouseEvent) => {
        const newMouseData = new MouseData(
          displayedCanvas,
          event,
          panOffset,
          scale
        )

        setMouseData(mouseData => {
          if (mouseData.equals(newMouseData)) {
            // This may seem redundant given we have just shown that they are
            // functionally the same, but returing _exactly_ the same object as
            // was previously in the state means that it won't needlessly
            // re-render. The same goes for the other uses of this pattern.
            return mouseData
          } else {
            return newMouseData
          }
        })

        // Update the hovered item and resize node updates.
        itemsDispatch({
          type: ItemsActionType.MoveMouse,
          displayedContext,
          mouseData: newMouseData,
          toolMode
        })

        // Handle panning, if it needs updating.

        if (toolMode === ToolMode.Pan && actionStartData !== null) {
          panAndZoomDispatch({
            type: PanAndZoomActionType.Pan,
            actionGridStart: actionStartData.grid,
            displayedMousePixel: newMouseData.displayedMousePixel
          })
        }
      }
      displayedCanvas.addEventListener('mousemove', mouseMoveListener)

      // Track the mouse wheel for zooming.
      const wheelListener = (event: WheelEvent) => {
        // Get the mouse pixel coordinates within the displayed canvas.
        const { left, top } = displayedCanvas.getBoundingClientRect()
        const displayedMousePixel =
          new Point(event.clientX - left, event.clientY - top)

        panAndZoomDispatch({
          type: event.deltaY < 0
            ? PanAndZoomActionType.ZoomIn
            : PanAndZoomActionType.ZoomOut,
          displayedMousePixel
        })
      }
      displayedCanvas.addEventListener('wheel', wheelListener)

      // Cleanup.
      return () => {
        displayedCanvas.removeEventListener('mousemove', mouseMoveListener)
        displayedCanvas.removeEventListener('wheel', wheelListener)
      }
    },
    [
      panOffset,
      toolMode,
      selectedItemData,
      actionStartData,
      scale,
      itemsDispatch,
      panAndZoomDispatch
    ]
  )

  // Set up resizing and redrawing of the displayed canvas.
  useEffect(
    () => {
      const displayedCanvas = displayedCanvasReference.current
      const displayedContext = displayedCanvas?.getContext('2d') ?? null
      if (displayedCanvas === null || displayedContext === null) {
        console.error(
          'Failed to get displayed canvas or its context. Cannot setup ' +
          'resizing and redrawing.'
        )
        return
      }

      // Ensure the floor plan canvas is drawn onto the displayed canvas, and
      // that the displayed canvas has the same size in pixels as the number of
      // pixels it takes up on the page.

      const resizeAndRedrawDisplayedCanvas = () => {
        if (
          displayedCanvas.width !== displayedCanvas.clientWidth ||
          displayedCanvas.height !== displayedCanvas.clientHeight
        ) {
          displayedCanvas.width = displayedCanvas.clientWidth
          displayedCanvas.height = displayedCanvas.clientHeight

          panAndZoomDispatch({
            type: PanAndZoomActionType.Update,
            displayWidth: displayedCanvas.width,
            displayHeight: displayedCanvas.height
          })
        }

        // Fill the canvas background.
        displayedContext.fillStyle = 'lightgrey'
        displayedContext.fillRect(
          0,
          0,
          displayedCanvas.width,
          displayedCanvas.height
        )

        // Draw the canvas onto it.
        displayedContext.drawImage(
          canvas,
          panOffset.x,
          panOffset.y,
          CANVAS_WIDTH * scale,
          CANVAS_HEIGHT * scale
        )
      }
      window.addEventListener('resize', resizeAndRedrawDisplayedCanvas)

      // Start the canvas off at the right size.
      resizeAndRedrawDisplayedCanvas()

      // Cleanup.
      return () => {
        window.removeEventListener('resize', resizeAndRedrawDisplayedCanvas)
      }
    },
    [canvas, panOffset, scale, panAndZoomDispatch]
  )

  // Update the canvas.
  useEffect(
    () => {
      const displayedContext =
        displayedCanvasReference.current?.getContext('2d') ?? null

      const newCanvas = document.createElement('canvas')
      newCanvas.width = CANVAS_WIDTH
      newCanvas.height = CANVAS_HEIGHT
      const context = newCanvas.getContext('2d')

      if (context === null || displayedContext === null) {
        console.error('Failed to get canvas contexts. Cannot update them.')
        return
      }

      // Clear the canvas.
      context.fillStyle = 'white'
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT)

      // Draw the background grid.
      context.drawImage(gridCanvas, 0, 0)

      const toDraw: Item[] = [...Object.values(items), camera]

      // Add the in-progress floor, just below the walls.
      if (toolMode === ToolMode.Floor && actionStartData !== null) {
        const floor = new Floor(actionStartData.grid, mouseData.nearestGrid)
        floor.order = Wall.defaultOrder - 0.5
        toDraw.push(floor)
      }

      // Add the in-progress wall, just above the other walls.
      if (
        [ToolMode.Wall, ToolMode.LinkedWall].includes(toolMode) &&
        actionStartData !== null
      ) {
        const wall = new Wall(actionStartData.grid, mouseData.nearestGrid)
        wall.order = Wall.defaultOrder + 0.5
        toDraw.push(wall)
      }

      toDraw.sort((a, b) => a.order - b.order)

      for (const item of toDraw) {
        // If we don't have a whole number display mode, then it is new.
        let displayMode: DisplayMode = DisplayMode.New
        if (item.order === Math.floor(item.order)) {
          displayMode =
            item.getDisplayMode(visiblyHoveredItemData, selectedItemData)
        }

        item.draw(context, displayMode)
      }

      // Draw the resize nodes for the selected item.
      if (selectedItemData) {
        selectedItemData.item.drawResizeNodes(context)
      }

      // Draw the tool preview, if we aren't part way through something, or
      // hovering something.
      if (actionStartData === null && visiblyHoveredItemData === undefined) {
        drawToolPreview(context, mouseData, toolMode)
      }

      // Save our constructed canvas. It will be drawn on by the above
      // useEffect.
      setCanvas(newCanvas)
    },
    // Anything in the below array changing will trigger a re-render.
    [
      mouseData,
      actionStartData,
      items,
      selectedItemData,
      toolMode,
      camera,
      visiblyHoveredItemData,
      imagesLoaded
    ]
  )

  // Setup mouseup and mousedown listeners. This is a separate effect because
  // it will depend on many things.
  useEffect(
    () => {
      const displayedCanvas = displayedCanvasReference.current
      const displayedContext = displayedCanvas?.getContext('2d') ?? null
      if (displayedCanvas === null || displayedContext === null) {
        console.error(
          'Failed to get displayed canvas or its context. Cannot setup ' +
          'mouseup and mousedown handlers.'
        )
        return
      }

      // Handle the mouse being depressed. Perhaps this is the start of a drag,
      // or perhaps it is the start of a single click action (in which case it
      // won't be handled until the mouseup event, when it finishes).
      const mousedownListener = (event: MouseEvent) => {
        const currentMouseData = new MouseData(
          displayedCanvas,
          event,
          panOffset,
          scale
        )

        if (event.button !== 0) {
          // Only handle left-clicks.
        } else if (
          [ToolMode.Delete, ToolMode.Camera].includes(toolMode)
        ) {
          // The delete and camera tools only trigger on mouse up.
        } else if (selectedItemData && hoveredResizeNode) {
          // If there is a selected item, and we have hovered on a resize node,
          // then, we want to edit it.

          setActionStartData({
            grid: hoveredResizeNode.oppositeGridPoint,
            editing: true
          })
          itemsDispatch({ type: ItemsActionType.DeleteSelected })
        } else if (
          !(toolMode === ToolMode.LinkedWall && actionStartData !== null) &&
          !(toolMode === ToolMode.Pan && !currentMouseData.onFloorPlan)
        ) {
          // Then, unless we are currently drawing a continuation of a wall with
          // the linked wall tool with the mouse previously up (in which case we
          // don't want to do anything until the next mouseup where this wall
          // section will get confirmed), or we are using the pan tool and
          // aren't currently pointing at the floor plan, we are performing a
          // new action, as determined by the current tool.

          setActionStartData({
            grid: currentMouseData.nearestGrid,
            editing: false
          })
        }

        // Handle a mouse move to the current location of the mouse in case
        // hover stuff needs to be updated.
        itemsDispatch({
          type: ItemsActionType.MoveMouse,
          displayedContext,
          mouseData: currentMouseData,
          toolMode
        })
      }

      // Handle the mouse being released. Perhaps this is the end of a drag, or
      // perhaps it is the completion of a single click action.
      const mouseupListener = (event: MouseEvent) => {
        const currentMouseData = new MouseData(
          displayedCanvas,
          event,
          panOffset,
          scale
        )

        // If the points are the same, we don't want to count this as a drawing.
        // This is here because otherwise instead of selecting something on a
        // single click we would draw and then cancel a zero-size object.
        let actionGridDataToUse = actionStartData
        if (drawingIsUnviable) {
          if (actionStartData !== null) setActionStartData(null)
          actionGridDataToUse = null
        }

        if (event.button !== 0) {
          // Only handle left-clicks.
        } else if (toolMode === ToolMode.Delete) {
          // Handle a delete click.

          itemsDispatch({ type: ItemsActionType.DeleteHovered })
        } else if (toolMode === ToolMode.Camera) {
          // The camera doesn't work like other tools because it is a single
          // click to move it to that location, so it benefits from being
          // handled specifically.
          itemsDispatch({
            type: ItemsActionType.SetCamera,
            newCamera: new Camera(
              currentMouseData.topLeftGrid,
              currentMouseData.nearestNonCentreInternalCellPosition
            )
          })
        } else if (toolMode === ToolMode.Pan) {
          // Stop panning on release.
          setActionStartData(null)
        } else if (actionGridDataToUse !== null) {
          // Next we want to prioritise adding any in-progress items.

          // If we are drawing a floor with no width or no height, we want to
          // cancel.
          if (
            toolMode === ToolMode.Floor && (
              actionGridDataToUse.grid.x === currentMouseData.nearestGrid.x ||
              actionGridDataToUse.grid.y === currentMouseData.nearestGrid.y
            )
          ) {
            setActionStartData(null)
            return
          }

          // Otherwise create the item.
          let newItem: Item
          let wallToLink: Wall | undefined
          switch (toolMode) {
            case ToolMode.Wall:
              newItem =
                new Wall(actionGridDataToUse.grid, currentMouseData.nearestGrid)
              break

            case ToolMode.LinkedWall:
              wallToLink = newItem =
                new Wall(actionGridDataToUse.grid, currentMouseData.nearestGrid)
              break

            case ToolMode.Floor:
              newItem = new Floor(
                actionGridDataToUse.grid,
                currentMouseData.nearestGrid
              )
              break

            case ToolMode.Bench:
              newItem = new Bench(
                currentMouseData.topLeftGrid,
                currentMouseData.nearestNonCentreInternalCellPosition
              )
              break

            case ToolMode.Frame:
              newItem = new Frame(
                currentMouseData.topLeftGrid,
                currentMouseData.nearestNonCentreInternalCellPosition
              )
              break

            default: {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const test: never = toolMode
              console.error('Failed to account for all potential ToolModes')
              return
            }
          }

          // Add the item to the list of items.
          itemsDispatch({
            type: ItemsActionType.Add,
            item: newItem,
            editing: actionGridDataToUse.editing
          })

          // Immediately start a new wall if we are in linked wall mode. This
          // does mean that they can be making a wall this way without having
          // the mouse down, but I think this is okay.
          if (wallToLink !== undefined) {
            setActionStartData({ grid: wallToLink.points[1], editing: false })
          } else {
            setActionStartData(null)
          }
        } else if (
          selectedItemData !== undefined &&
          visiblyHoveredItemData === undefined &&
          hoveredResizeNode === undefined
        ) {
          // If we have something selected and we are clicking on nothing, we
          // want to clear our selection.

          itemsDispatch({ type: ItemsActionType.Deselect })
        } else if (visiblyHoveredItemData) {
          // Otherwise, if there is an item hovered (noting that the hover
          // detection takes into account the current tool), we want to select
          // it.

          itemsDispatch({ type: ItemsActionType.SelectHovered })
        }

        // Handle a mouse move to the current location of the mouse in case
        // hover stuff needs to be updated.
        itemsDispatch({
          type: ItemsActionType.MoveMouse,
          displayedContext,
          mouseData: currentMouseData,
          toolMode
        })
      }

      displayedCanvas.addEventListener('mousedown', mousedownListener)
      displayedCanvas.addEventListener('mouseup', mouseupListener)

      // Cleanup.
      return () => {
        displayedCanvas.removeEventListener('mousedown', mousedownListener)
        displayedCanvas.removeEventListener('mouseup', mouseupListener)
      }
    },
    [
      toolMode,
      actionStartData,
      visiblyHoveredItemData,
      hoveredResizeNode,
      selectedItemData,
      itemsDispatch,
      drawingIsUnviable,
      panOffset,
      scale
    ]
  )

  return <div className="FloorPlanDesigner">
    <canvas ref={displayedCanvasReference} className={
      toolMode === ToolMode.Pan && mouseData.onFloorPlan
        ? actionStartData === null
          ? 'pan-selected'
          : 'panning'
        : ''
    }/>
  </div>
}
