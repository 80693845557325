import Floor from '../models/Floor'
import JSONType, { JSONObject } from '../models/JSONType'
import Wall from '../models/Wall'
import { ItemsState } from './itemsReducer'

/**
 * Get the JSON object that represents the given items, ready for sending to the
 * backend.
 * @param itemsState The object detailing the collection of items to serialise.
 * @returns The JSON string representing the items.
 */
export default function serialiseItems (itemsState: ItemsState): string {
  const objectToSerialise: {
    floors: JSONType[],
    walls: JSONType[],
    camera: JSONObject,
    assets: JSONType[]
  } = {
    floors: [],
    walls: [],
    camera: itemsState.camera.getObjectForSerialisation(),
    assets: []
  }

  for (const item of Object.values(itemsState.items)) {
    if (item instanceof Floor) {
      objectToSerialise.floors.push(item.getObjectForSerialisation())
    } else if (item instanceof Wall) {
      objectToSerialise.walls.push(item.getObjectForSerialisation())
    } else { // Benches and frames.
      objectToSerialise.assets.push(item.getObjectForSerialisation())
    }
  }

  return JSON.stringify(objectToSerialise)
}
