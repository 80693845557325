import {
  X_INCREMENT_WIDTH,
  Y_INCREMENT_HEIGHT,
  COLOUR_FOR_ITEM_TYPE_FOR_DISPLAY_MODE,
  NORMAL_LINE_DASH,
  FLOOR_LINE_WIDTH,
  FLOOR_STROKE_COLOUR,
  FLOOR_LINE_DASH
} from '../config'

import DisplayMode from './DisplayMode'
import Item from './Item'
import ItemType from './ItemType'
import Point from './Point'
import ResizeNode from './ResizeNode'

/**
 * A class for handling floors.
 */
export default class Floor extends Item {
  static defaultOrder = 0
  order = Floor.defaultOrder

  points: [Point, Point]
  path: Path2D
  resizeNodes: [ResizeNode, ResizeNode, ResizeNode, ResizeNode]

  /**
   * Create a new floor object between two grid point corners.
   * @param gridPoint1 The first grid point.
   * @param gridPoint2 The second grid point.
   */
  constructor (gridPoint1: Point, gridPoint2: Point) {
    super()

    this.points = [gridPoint1, gridPoint2]

    const gridX = Math.min(gridPoint1.x, gridPoint2.x)
    const gridY = Math.min(gridPoint1.y, gridPoint2.y)
    const gridWidth = Math.max(gridPoint1.x, gridPoint2.x) - gridX
    const gridHeight = Math.max(gridPoint1.y, gridPoint2.y) - gridY

    this.path = new Path2D()
    this.path.rect(
      gridX * X_INCREMENT_WIDTH,
      gridY * Y_INCREMENT_HEIGHT,
      gridWidth * X_INCREMENT_WIDTH,
      gridHeight * Y_INCREMENT_HEIGHT
    )

    const topLeft = new Point(gridX, gridY)
    const topRight = new Point(gridX + gridWidth, gridY)
    const bottomRight = new Point(gridX + gridWidth, gridY + gridHeight)
    const bottomLeft = new Point(gridX, gridY + gridHeight)

    this.resizeNodes = [
      new ResizeNode(topLeft, bottomRight),
      new ResizeNode(topRight, bottomLeft),
      new ResizeNode(bottomRight, topLeft),
      new ResizeNode(bottomLeft, topRight)
    ]
  }

  /**
   * Draw this floor onto the given canvas, in the given display mode.
   * @param context The canvas context to draw to.
   * @param displayMode The mode to draw the floor in. This determines the
   * colour.
   */
  draw (context: CanvasRenderingContext2D, displayMode: DisplayMode) {
    context.setLineDash(FLOOR_LINE_DASH)

    context.fillStyle =
    COLOUR_FOR_ITEM_TYPE_FOR_DISPLAY_MODE[ItemType.Floor][displayMode]
    context.fill(this.path)

    context.strokeStyle = FLOOR_STROKE_COLOUR
    context.lineWidth = FLOOR_LINE_WIDTH
    context.stroke(this.path)

    context.setLineDash(NORMAL_LINE_DASH)
  }

  getObjectForSerialisation () {
    const firstCornerConverted = this.points[0].toggleBackendCoordinates()
    const secondCornerConverted = this.points[1].toggleBackendCoordinates()

    // I just wanted to spell it out explicitly, so we can see the structure.
    return [
      { x: firstCornerConverted.x, y: firstCornerConverted.y },
      { x: secondCornerConverted.x, y: secondCornerConverted.y }
    ]
  }
}
