import Item from './Item'
import InternalCellPosition, { Direction } from './InternalCellPosition'
import Point from './Point'
import DisplayMode from './DisplayMode'
import {
  FRAME_INSET,
  FRAME_WIDTH,
  COLOUR_FOR_ITEM_TYPE_FOR_DISPLAY_MODE,
  NORMAL_LINE_DASH,
  X_INCREMENT_WIDTH,
  Y_INCREMENT_HEIGHT,
  FRAME_LINE_WIDTH
} from '../config'
import ItemType from './ItemType'

// This class is, at the time of writing _literally_ identical to the Bench
// class, other than this comment and various capitalisations of the word bench
// being replaced with the word frame. This would, one would think, make them a
// good candidate for some kind of abstraction. However, I believe these
// similarities are not inherit to the idea of benches and frames, just to how
// we have currently chosen to represent them (as lines). I expect these
// representations may evolve and, indeed, diverge. As such, I don't want to
// restrict that potential with a common abstraction.

export default class Frame extends Item {
  static defaultOrder = 3
  order = Frame.defaultOrder

  topLeftGridPoint: Point
  direction: Direction

  resizeNodes = []
  path: Path2D

  constructor (topLeftGridPoint: Point, direction: Direction) {
    super()

    this.topLeftGridPoint = topLeftGridPoint
    this.direction = direction

    this.path = new Path2D()

    const halfWidth = FRAME_WIDTH / 2
    const topLeftPixel = topLeftGridPoint.pixelPoint()

    switch (direction) {
      case InternalCellPosition.Top:
        this.path.moveTo(
          topLeftPixel.x + (X_INCREMENT_WIDTH / 2) - halfWidth,
          topLeftPixel.y + FRAME_INSET
        )
        this.path.lineTo(
          topLeftPixel.x + (X_INCREMENT_WIDTH / 2) + halfWidth,
          topLeftPixel.y + FRAME_INSET
        )

        break

      case InternalCellPosition.Bottom:
        this.path.moveTo(
          topLeftPixel.x + (X_INCREMENT_WIDTH / 2) - halfWidth,
          topLeftPixel.y + Y_INCREMENT_HEIGHT - FRAME_INSET
        )
        this.path.lineTo(
          topLeftPixel.x + (X_INCREMENT_WIDTH / 2) + halfWidth,
          topLeftPixel.y + Y_INCREMENT_HEIGHT - FRAME_INSET
        )

        break

      case InternalCellPosition.Left:
        this.path.moveTo(
          topLeftPixel.x + FRAME_INSET,
          topLeftPixel.y + (Y_INCREMENT_HEIGHT / 2) - halfWidth
        )
        this.path.lineTo(
          topLeftPixel.x + FRAME_INSET,
          topLeftPixel.y + (Y_INCREMENT_HEIGHT / 2) + halfWidth
        )

        break

      case InternalCellPosition.Right:
        this.path.moveTo(
          topLeftPixel.x + Y_INCREMENT_HEIGHT - FRAME_INSET,
          topLeftPixel.y + (Y_INCREMENT_HEIGHT / 2) - halfWidth
        )
        this.path.lineTo(
          topLeftPixel.x + Y_INCREMENT_HEIGHT - FRAME_INSET,
          topLeftPixel.y + (Y_INCREMENT_HEIGHT / 2) + halfWidth
        )

        break
    }
  }

  getObjectForSerialisation () {
    const backendTopLeft = this.topLeftGridPoint.toggleBackendCoordinates()
    const backendBottomRight = new Point(
      backendTopLeft.x,
      backendTopLeft.y - 1
    )

    type Position = 'top' | 'bottom' | 'left' | 'right'
    const positionMapping: Record<Direction, Position> = {
      [InternalCellPosition.Top]: 'top',
      [InternalCellPosition.Bottom]: 'bottom',
      [InternalCellPosition.Left]: 'left',
      [InternalCellPosition.Right]: 'right'
    }

    return {
      name: 'frame',
      x: backendBottomRight.x,
      y: backendBottomRight.y,
      position: positionMapping[this.direction]
    }
  }

  draw (
    context: CanvasRenderingContext2D,
    displayMode: DisplayMode
  ) {
    context.setLineDash(NORMAL_LINE_DASH)

    context.strokeStyle =
      COLOUR_FOR_ITEM_TYPE_FOR_DISPLAY_MODE[ItemType.Frame][displayMode]
    context.lineWidth = FRAME_LINE_WIDTH

    context.stroke(this.path)
  }

  containsPixelPoint (
    context: CanvasRenderingContext2D,
    pixelPoint: Point
  ) {
    // Just ensure that the stroke width is set correctly before checking if
    // this contains something.
    context.lineWidth = FRAME_LINE_WIDTH
    return super.containsPixelPoint(context, pixelPoint)
  }
}
