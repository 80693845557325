import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom'

import Header from './components/Header'

import './App.css'
import HomePage from './components/HomePage'
import HelpPage from './components/HelpPage'
import HelpImportingIntoUnityPage from './components/HelpImportingIntoUnityPage'
import HelpModifyingInUnityPage from './components/HelpModifyingInUnityPage'
import HelpUsingTheTool from './components/HelpUsingTheToolPage'
import ToolPage from './components/ToolPage'

function AppWithoutRouter ({ location }: RouteComponentProps) {
  return (
    <div className="App">
      <Header />

      <div className="main">
        <Switch>
          <Route path="/help/using-the-tool" exact>
            <div className="content"><HelpUsingTheTool/></div>
          </Route>
          <Route path="/help/modifying-in-unity" exact>
            <div className="content"><HelpModifyingInUnityPage/></div>
          </Route>
          <Route path="/help/importing-into-unity" exact>
            <div className="content"><HelpImportingIntoUnityPage/></div>
          </Route>
          <Route path="/help" exact>
            <div className="content"><HelpPage/></div>
          </Route>
          <Route path="/tool" exact component={ToolPage} />
          <Route path="/" exact component={HomePage} />
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>

      {location.pathname !== '/tool' && <footer className="dark">
        <div className="content">
          <p>Created by COMP3988_T17_01_Group5 for USYD Tech Lab</p>
        </div>
      </footer>}
    </div>
  )
}

const AppWithRouter = withRouter(props => <AppWithoutRouter {...props}/>)

export default function App () {
  return <Router><AppWithRouter /></Router>
}
