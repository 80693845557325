// TODO: Determine if this really needs to exist...

/**
 * The kinds of items that can be displayed on the canvas.
 */
enum ItemType {
  Wall = 'Wall',
  Floor = 'Floor',
  Camera = 'Camera',
  Bench = 'Bench',
  Frame = 'Frame'
}

export default ItemType
