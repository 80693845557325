import Item from './Item'
import InternalCellPosition, { Direction } from './InternalCellPosition'
import Point from './Point'
import DisplayMode from './DisplayMode'
import {
  BENCH_INSET,
  BENCH_LINE_WIDTH,
  BENCH_WIDTH,
  COLOUR_FOR_ITEM_TYPE_FOR_DISPLAY_MODE,
  NORMAL_LINE_DASH,
  X_INCREMENT_WIDTH,
  Y_INCREMENT_HEIGHT
} from '../config'
import ItemType from './ItemType'

export default class Bench extends Item {
  static defaultOrder = 3
  order = Bench.defaultOrder

  topLeftGridPoint: Point
  direction: Direction

  resizeNodes = []
  path: Path2D

  constructor (topLeftGridPoint: Point, direction: Direction) {
    super()

    this.topLeftGridPoint = topLeftGridPoint
    this.direction = direction

    this.path = new Path2D()

    const halfWidth = BENCH_WIDTH / 2
    const topLeftPixel = topLeftGridPoint.pixelPoint()

    switch (direction) {
      case InternalCellPosition.Top:
        this.path.moveTo(
          topLeftPixel.x + (X_INCREMENT_WIDTH / 2) - halfWidth,
          topLeftPixel.y + BENCH_INSET
        )
        this.path.lineTo(
          topLeftPixel.x + (X_INCREMENT_WIDTH / 2) + halfWidth,
          topLeftPixel.y + BENCH_INSET
        )

        break

      case InternalCellPosition.Bottom:
        this.path.moveTo(
          topLeftPixel.x + (X_INCREMENT_WIDTH / 2) - halfWidth,
          topLeftPixel.y + Y_INCREMENT_HEIGHT - BENCH_INSET
        )
        this.path.lineTo(
          topLeftPixel.x + (X_INCREMENT_WIDTH / 2) + halfWidth,
          topLeftPixel.y + Y_INCREMENT_HEIGHT - BENCH_INSET
        )

        break

      case InternalCellPosition.Left:
        this.path.moveTo(
          topLeftPixel.x + BENCH_INSET,
          topLeftPixel.y + (Y_INCREMENT_HEIGHT / 2) - halfWidth
        )
        this.path.lineTo(
          topLeftPixel.x + BENCH_INSET,
          topLeftPixel.y + (Y_INCREMENT_HEIGHT / 2) + halfWidth
        )

        break

      case InternalCellPosition.Right:
        this.path.moveTo(
          topLeftPixel.x + Y_INCREMENT_HEIGHT - BENCH_INSET,
          topLeftPixel.y + (Y_INCREMENT_HEIGHT / 2) - halfWidth
        )
        this.path.lineTo(
          topLeftPixel.x + Y_INCREMENT_HEIGHT - BENCH_INSET,
          topLeftPixel.y + (Y_INCREMENT_HEIGHT / 2) + halfWidth
        )

        break
    }
  }

  getObjectForSerialisation () {
    const backendTopLeft = this.topLeftGridPoint.toggleBackendCoordinates()
    const backendBottomRight = new Point(
      backendTopLeft.x,
      backendTopLeft.y - 1
    )

    type Position = 'top' | 'bottom' | 'left' | 'right'
    const positionMapping: Record<Direction, Position> = {
      [InternalCellPosition.Top]: 'top',
      [InternalCellPosition.Bottom]: 'bottom',
      [InternalCellPosition.Left]: 'left',
      [InternalCellPosition.Right]: 'right'
    }

    return {
      name: 'bench',
      x: backendBottomRight.x,
      y: backendBottomRight.y,
      position: positionMapping[this.direction]
    }
  }

  draw (
    context: CanvasRenderingContext2D,
    displayMode: DisplayMode
  ) {
    context.setLineDash(NORMAL_LINE_DASH)

    context.strokeStyle =
      COLOUR_FOR_ITEM_TYPE_FOR_DISPLAY_MODE[ItemType.Bench][displayMode]
    context.lineWidth = BENCH_LINE_WIDTH

    context.stroke(this.path)
  }

  containsPixelPoint (
    context: CanvasRenderingContext2D,
    pixelPoint: Point
  ) {
    // Just ensure that the stroke width is set correctly before checking if
    // this contains something.
    context.lineWidth = BENCH_LINE_WIDTH
    return super.containsPixelPoint(context, pixelPoint)
  }
}
