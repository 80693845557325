import {
  PREVIEW_OPACITY,
  X_INCREMENT_WIDTH,
  Y_INCREMENT_HEIGHT
} from '../config'
import DisplayMode from './DisplayMode'
import { Direction } from './InternalCellPosition'
import Item from './Item'
import Point from './Point'

export default abstract class ImageItem extends Item {
  resizeNodes = []

  // A mapping that stores which image should be used
  abstract imageMapping: Record<Direction, HTMLImageElement>

  topLeftGridPoint: Point
  direction: Direction

  /**
   * Create a new image item object in the cell with the given top left gird
   * point, facing in the given direction.
   * @param topLeftGridPoint The top left grid coordinate point of the cell to
   * draw the camera in.
   * @param direction The side of the cell that the camera is facing towards.
   */
  constructor (topLeftGridPoint: Point, direction: Direction) {
    super()

    this.topLeftGridPoint = topLeftGridPoint
    this.direction = direction
  }

  draw (
    context: CanvasRenderingContext2D,
    displayMode: DisplayMode
  ) {
    const topLeftPixelPoint = this.topLeftGridPoint.pixelPoint()
    context.globalAlpha =
      displayMode === DisplayMode.Preview ? PREVIEW_OPACITY : 1
    context.drawImage(
      this.imageMapping[this.direction],
      topLeftPixelPoint.x,
      topLeftPixelPoint.y,
      X_INCREMENT_WIDTH,
      Y_INCREMENT_HEIGHT
    )
    context.globalAlpha = 1
  }
}
