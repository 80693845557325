import React from 'react'
import HelpCards from '../HelpCards'

export default function HelpPage () {
  return <div className="HelpPage">
    <h1>Help</h1>
    <p>
      From simple guidance getting started using the floor plan tool, to details
      on how to do some advanced manipulation of the tool's output in Unity,
      this is where to come for help.
    </p>
    <p>Our help pages are broken into the following sections:</p>
    <HelpCards />
  </div>
}
