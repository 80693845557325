/*
Generate the background grid canvas once of screen, such that we don't need to
draw all the lines again every time we update the canvas.
*/

import {
  CANVAS_WIDTH,
  CANVAS_HEIGHT,
  NUMBER_OF_CELLS_WIDE,
  NUMBER_OF_CELLS_HIGH,
  X_INCREMENT_WIDTH,
  Y_INCREMENT_HEIGHT
} from '../config'

/**
 * A canvas containing the background grid.
 */
const gridCanvas = document.createElement('canvas')

gridCanvas.width = CANVAS_WIDTH
gridCanvas.height = CANVAS_HEIGHT

const context = gridCanvas.getContext('2d')

if (context !== null) {
  // Clear the canvas.
  context.clearRect(0, 0, context.canvas.width, context.canvas.height)

  // Draw the grid-lines.
  context.strokeStyle = 'grey'
  context.lineWidth = 1
  for (let increment = 1; increment < NUMBER_OF_CELLS_WIDE; increment++) {
    const x = increment * X_INCREMENT_WIDTH

    context.beginPath()
    context.moveTo(x, 0)
    context.lineTo(x, CANVAS_HEIGHT)
    context.stroke()
  }
  for (let increment = 1; increment < NUMBER_OF_CELLS_HIGH; increment++) {
    const y = increment * Y_INCREMENT_HEIGHT

    context.beginPath()
    context.moveTo(0, y)
    context.lineTo(CANVAS_WIDTH, y)
    context.stroke()
  }
} else {
  console.error('Failed to get gridCanvas context. Could not draw to it.')
}

export default gridCanvas
