import React from 'react'

import './styles.css'

type Props = {
  children?: React.ReactNode
  title: string
}

export default function Modal ({ children, title }: Props) {
  return <div className="Modal">
    <div className="actual">
      <div className="header dark">{title}</div>
      <div className="body">{children}</div>
    </div>
  </div>
}
