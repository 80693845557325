import React from 'react'
import Edit from '@material-ui/icons/Edit'
import Timeline from '@material-ui/icons/Timeline'
import CropSquare from '@material-ui/icons/CropSquare'
import Delete from '@material-ui/icons/Delete'
import Videocam from '@material-ui/icons/Videocam'
import GetApp from '@material-ui/icons/GetApp'
import PanTool from '@material-ui/icons/PanTool'
import Weekend from '@material-ui/icons/Weekend'
import FilterFrames from '@material-ui/icons/FilterFrames'
import CenterFocusStrong from '@material-ui/icons/CenterFocusStrong'

import ToolMode from '../../models/ToolMode'

import './styles.css'

// TODO: Resolve react's complaint about the use of checked.

type Props = {
  toolMode: ToolMode
  setToolMode: (newToolMode: ToolMode) => void
  resetZoom: () => void
  triggerDownload: () => Promise<void>
}

export default function ToolControls ({
  toolMode,
  setToolMode,
  resetZoom,
  triggerDownload
}: Props) {
  return <div className="ToolControls">
    <input
      type="radio"
      id="tool-mode-pan"
      name="tool-mode"
      checked={toolMode === ToolMode.Pan}
      onClick={() => setToolMode(ToolMode.Pan)}
    ></input>
    <label htmlFor="tool-mode-pan" title="Pan">
      <PanTool
        fontSize="large"
        color={toolMode === ToolMode.Pan ? 'inherit' : 'disabled'}
      />
    </label>

    <input
      type="radio"
      id="tool-mode-floor"
      name="tool-mode"
      checked={toolMode === ToolMode.Floor}
      onClick={() => setToolMode(ToolMode.Floor)}
    ></input>
    <label htmlFor="tool-mode-floor" title="Floors">
      <CropSquare
        fontSize="large"
        color={toolMode === ToolMode.Floor ? 'inherit' : 'disabled'}
      />
    </label>

    <input
      type="radio"
      id="tool-mode-wall"
      name="tool-mode"
      checked={toolMode === ToolMode.Wall}
      onClick={() => setToolMode(ToolMode.Wall)}
    ></input>
    <label htmlFor="tool-mode-wall" title="Walls">
      <Edit
        fontSize="large"
        color={toolMode === ToolMode.Wall ? 'inherit' : 'disabled'}
      />
    </label>

    <input
      type="radio"
      id="tool-mode-linked-wall"
      name="tool-mode"
      checked={toolMode === ToolMode.LinkedWall}
      onClick={() => setToolMode(ToolMode.LinkedWall)}
    ></input>
    <label htmlFor="tool-mode-linked-wall" title="Linked Walls">
      <Timeline
        fontSize="large"
        color={toolMode === ToolMode.LinkedWall ? 'inherit' : 'disabled'}
      />
    </label>

    <input
      type="radio"
      id="tool-mode-frame"
      name="tool-mode"
      checked={toolMode === ToolMode.Frame}
      onClick={() => setToolMode(ToolMode.Frame)}
    ></input>
    <label htmlFor="tool-mode-frame" title="Frames">
      <FilterFrames
        fontSize="large"
        color={toolMode === ToolMode.Frame ? 'inherit' : 'disabled'}
      />
    </label>

    <input
      type="radio"
      id="tool-mode-bench"
      name="tool-mode"
      checked={toolMode === ToolMode.Bench}
      onClick={() => setToolMode(ToolMode.Bench)}
    ></input>
    <label htmlFor="tool-mode-bench" title="Benches">
      <Weekend
        fontSize="large"
        color={toolMode === ToolMode.Bench ? 'inherit' : 'disabled'}
      />
    </label>

    <input
      type="radio"
      id="tool-mode-camera"
      name="tool-mode"
      checked={toolMode === ToolMode.Camera}
      onClick={() => setToolMode(ToolMode.Camera)}
    ></input>
    <label htmlFor="tool-mode-camera" title="Camera">
      <Videocam
        fontSize="large"
        color={toolMode === ToolMode.Camera ? 'inherit' : 'disabled'}
      />
    </label>

    <input
      type="radio"
      id="tool-mode-delete"
      name="tool-mode"
      checked={toolMode === ToolMode.Delete}
      onClick={() => setToolMode(ToolMode.Delete)}
    ></input>
    <label htmlFor="tool-mode-delete" title="Delete">
      <Delete
        fontSize="large"
        color={toolMode === ToolMode.Delete ? 'inherit' : 'disabled'}
      />
    </label>

    <hr />

    <div className="control" title="Recentre">
      <CenterFocusStrong fontSize="large" onClick={resetZoom}/>
    </div>

    <div className="control" title="Download">
      <GetApp fontSize="large" onClick={triggerDownload}/>
    </div>
  </div>
}
