import { RESIZE_NODE_COLOUR, RESIZE_NODE_RADIUS } from '../config'
import Drawable from './Drawable'
import Point from './Point'

/**
 * A class for handling resize nodes. Those little nobbly things that show up
 * when you want to change the size of something.
 */
export default class ResizeNode implements Drawable {
  path: Path2D
  oppositeGridPoint: Point

  /**
   * Create a resize node.
   * @param gridPoint The grid point to draw the resize node at.
   * @param oppositeGridPoint This is the opposite point on the object. This is
   * stored here for easy access, as, when this node is selected to use to
   * resize something, it is the opposite node that becomes the anchor point.
   */
  constructor (gridPoint: Point, oppositeGridPoint: Point) {
    const pixelPoint = gridPoint.pixelPoint()

    this.path = new Path2D()
    this.path.arc(
      pixelPoint.x,
      pixelPoint.y,
      RESIZE_NODE_RADIUS,
      0,
      2 * Math.PI
    )

    this.oppositeGridPoint = oppositeGridPoint
  }

  draw (context: CanvasRenderingContext2D) {
    context.fillStyle = RESIZE_NODE_COLOUR
    context.fill(this.path)
  }

  containsPixelPoint (context: CanvasRenderingContext2D, pixelPoint: Point) {
    return context.isPointInPath(this.path, pixelPoint.x, pixelPoint.y)
  }
}
