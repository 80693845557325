import React from 'react'

import Crumbs from '../Crumbs'

import './styles.css'

export default function HelpModifyingInUnityPage () {
  return <div className='HelpModifyingInUnityPage'>
    <div className='contents'>
      <Crumbs links={[['Help', '/help']]} current='Modifying in Unity'/>
      <h1>Modifying your Unity Museum</h1>
      <p className='centred'>
        This tutorial page will run through how to modify and extend the museum
        template that's provided to you from the tool. If you haven't already,
        create your own museum template and follow the guide of <a
        href='/help/importing-into-unity'>importing your museum</a> into Unity.
        We will run through how to edit and texture objects in Unity as well as
        adding in prefabs and your own assets. We recommend beforehand you try
        the <code>Lego Microgame</code> tutorial by Unity, available when
        creating a new project.
      </p>
      <div className='section'>
        <h2 id='primitive-header'>
          Creating and Moving a Primitive Object
        </h2>
        <p>
          A <i>primitive</i> is a type of game object which can be  modified as
          a base Unity feature. These can be created in various ways:
        </p>
        <ul>
          <li>
            Navigate to <code>GameObject</code> at the top of Unity for a
            selection of objects to choose from.
          </li>
          <li>
            Right-click the <code>Scene</code> tab on the left for the same
            selection of objects.
          </li>
        </ul>
        <p>
          The object you chose will appear in the scene and in the <code>
          Hierarchy</code> tab on the left. You can click on this object for
          arrows to appear and manipulate. In the top-left, there are different
          buttons for shape manipulation to move, rotate, and re-size. Have a
          try to see what each option does! This can also be done in the <code>
          Inspector</code> tab. Give it a try!
        </p>
        <img
          src='/unity-edit-primitive.png'
          alt='How to edit a primitive object in Unity'
        />
      </div>
      <div className='section'>
        <h2 id='texture-header'>
          Adding a Texture to a Primitive Object
        </h2>
        <p>
          Adding in textured floors and walls will help improve the atmosphere
          of your museum. First, we need to get textures to apply to our
          primitives. To do so, we'll introduce downloading a texture from the
          Unity asset store:
        </p>
        <ol>
          <li>
            Navigate to <code>Window {'>'} Asset Store</code> and press <b>
            Search Online</b>.
          </li>
          <li>
            Find and download a free package that contains different <i>
            materials and textures</i>. For this tutorial, we have used an asset
            package found <a href='https://assetstore.unity.com/packages/2d/textures-materials/metals/yughues-free-metal-materials-12949'>
            here</a>.
          </li>
          <li>
            Back in Unity, navigate to <code>Window {'>'} Package Manager
            </code>.
          </li>
          <li>
            Select the package that you downloaded. If it isn't there, press
            refresh or check again that the package was installed in the asset
            store (make sure <code>My Assets</code> is chosen from the drop-down
            menu at the top of the window).
          </li>
          <li>
            In the bottom right, press <b>Download</b> and then <b>Import</b>.
            For the popup window, press <b>Import</b> one last time.
          </li>
        </ol>
        <p>
          You'll notice in your asset folder at the bottom of your Unity editor,
          a new folder has appeared. From there, you can drag the different
          textures straight into Unity onto your different objects and <i>voila
          </i> !
        </p>
        <img
          src='/unity-texturing.png'
          alt='How to texture a game object in Unity'
        />
      </div>
      <div className='section'>
        <h2 id='prefab-header'>
          Adding in New Custom Prefabs
        </h2>
        <p>
          Within the template provided, there are a number of prefabs such as
          furniture, frames and other objects that can be added other than basic
          primitives to add atmosphere to your museum. Let's take a look at how
          to add those into your scene:
        </p>
        <ol>
          <li>
            If you haven't already, load in the template museum scene provided
            by the template tool as outlined <a
            href='/help/importing-into-unity'>here</a>.
          </li>
          <li>
            In the assets folder, go to <code>Gallery Items {'> '}
            GalleryItems_Prefabs</code>
          </li>
          <li>
            You can now drag the prefabs in the scene like <a
            href='#texture-header'> texturing a primitive</a>.
          </li>
        </ol>
        <img
          src='/unity-prefab.png'
          alt='How to add a prefab in the Unity editor'
        />
      </div>
      <div className='section'>
        <h2>
          Inserting a New Asset into your Museum
        </h2>
      </div>
      <p>
        As you create exhibits and artworks to display, we need to add them into
        the museum scene. For this tutorial we will be adding in a <code>.jpg
        </code> file into the Unity scene. Afterwards, try adding in a 3D
        object!
      </p>
      <ol>
        <li>
          Drag the <code>.jpg</code> file into the <b>Assets</b> tab.
        </li>
        <img
          src='/unity-assets-add.png'
          alt='Adding a custom prefab to the Assets tab'
        />
        <li>
          Create a new <b>Plane</b> primitive in the scene (check <a
          href='#primitive-header'>here</a>).
        </li>
        <li>
          Drag the new asset onto the primitive in the scene (check <a
          href='#texture-header'>here</a>).
        </li>
        <li>
          Resize the primitive to suit the size of your new museum artwork. Just
          know that as you resize, it might distort your picture so be careful!
          Also have a try changing the settings in the <b>Inspector</b> tab.
        </li>
        <img
          src='/unity-prefab-custom.png'
          alt='Custom prefab added in the Unity scene'
        />
      </ol>
    </div>
  </div>
}
