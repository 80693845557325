import DisplayMode from './DisplayMode'
import Drawable from './Drawable'
import JSONType from './JSONType'
import OptionalItemData from './OptionalItemData'
import Point from './Point'
import ResizeNode from './ResizeNode'

/**
 * An class that should be extended by every "thing" that should be drawn to the
 * canvas - everything that the user would consider an object.
 */
export default abstract class Item implements Drawable {
  // defaultOrder and interactableToolModes would be abstract static properties
  // of this class, if abstract static properties existed in typescript.

  abstract resizeNodes: ResizeNode[]
  abstract path: Path2D

  /**
   * The priority with which an object should be drawn. Lower order numbers are
   * drawn first, and so appear behind higher order numbers. This property
   * should be a whole number such that special drawables can be inserted
   * inbetween standard ones, by using fractional offsets.
   */
   abstract order: number

   /**
    * Returns whether or not this path contains a given pixel coordinate on the
    * canvas - useful for testing if the mouse is currently hovering over it.
    * @param context The canvas context to use.
    * @param pixelPoint The pixel coordinate to check.
    * @returns True if the point is in the path (stroke or fill), false
    * otherwise.
    */
   containsPixelPoint (
     context: CanvasRenderingContext2D,
     pixelPoint: Point
   ): boolean {
     return context.isPointInPath(this.path, pixelPoint.x, pixelPoint.y) ||
      context.isPointInStroke(this.path, pixelPoint.x, pixelPoint.y)
   }

  /**
   * Draw this item onto the given canvas, in the given display mode.
   * @param context The canvas context to draw to.
   * @param displayMode The mode to draw the item in. This determines the
   * colour, and whether or not resize nodes are present.
   */
  abstract draw (
    context: CanvasRenderingContext2D,
    displayMode: DisplayMode
  ): void

  /**
   * Get the object that should be serialised to JSON to represent this item.
   */
  abstract getObjectForSerialisation (): JSONType

  drawResizeNodes (context: CanvasRenderingContext2D) {
    this.resizeNodes.forEach(resizeNode => resizeNode.draw(context))
  }

  getDisplayMode (
    hoveredItemData: OptionalItemData,
    selectedItemData: OptionalItemData
  ): DisplayMode {
    if (selectedItemData?.item === this) return DisplayMode.Selected
    if (hoveredItemData?.item === this) return DisplayMode.Hovered
    return DisplayMode.Normal
  }
}
